<template lang="pug">
  .icon-flashing-dot.animation-flashing._inline-block._rounded-full._bg-current._ml-5._mr-5(:class="[clss]")
</template>

<script>
export default {
  name: 'IconFlashingDot',
  props: {
    size: String
  },
  computed: {
    clss () {
      return {
        '_w-4 _h-4 md__w-5 md__h-5': this.size === 'small',
        '_w-5 _h-5 md__w-6 md__h-6': !this.size
      }
    }
  }
}
</script>

<style>
@keyframes flashing {
  0% {
    opacity:0;
  }
  50%{
    opacity:1;
  }
  100%{
    opacity:0;
  }
}
.animation-flashing{
  animation: flashing 1.5s infinite;
}
</style>
