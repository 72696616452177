<template lang="pug">
  //- fixed overlay layer for tap close
  ._fixed._w-full._h-full._top-0._left-0.tw-borders._flex._flex-col.lg__pointer-events-none(v-if="cart", v-show="visible", @click="$emit('close')")
    //- panel
    .cart._absolute._z-10._h-auto._max-h-full._top-0._right-0._pt-18.lg__pt-14._flex._pointer-events-none(:class="{'_w-full md__w-1x2 lg__w-1x4': cartCount}")
      //- body
      ._w-full._overflow-y-scroll.scrollbars-hidden._bg-gray-100._pointer-events-auto(ref="scrollbody", @mouseleave="$emit('close')", @click.stop)
        //- button.close-overlay.menu-icon.menu-icon--close(@click="$emit('close')", aria-label="Close Cart")
        //- cart items
        template(v-if="cartCount")
          section._flex-1
            ul._text-left._list-none._leading-tight
              //- subscriptions...
              li(v-for="(sub, i) in cartSubs")
                cart-line-item(:item="sub")
              //- items...
              li(v-for="(item, i) in cart.lineItems")
                cart-line-item(:item="item")

          footer._sticky._bottom-0._left-0._bg-gray-50.tw-borders
            //- free shipping progress
            //- ._p-6._text-gray-400._text-sm(v-if="freeShippingMin >= 0")
              div(:class="{'_text-black': cart.subtotalPrice >= freeShippingMin}")
                ._p-1
                  progress._w-full._block._h-3(:value="cart.subtotalPrice", :max="freeShippingMin", )
                p._text-left._mt-4._text
                  template(v-if="cart.subtotalPrice >= freeShippingMin") {{freeShippingLabels[1]}}
                  template(v-else)
                    | {{ freeShippingMin - cart.subtotalPrice }} {{ cart.currencyCode}} {{freeShippingLabels[0]}}
              prismic-rich-text._text-left.underline-links._leading-normal(:field="about.data.cart_footer_text", v-if="about && about.data && about.data.cart_footer_text.length")

            //- subtotal
            ._h-20._flex._justify-between._items-center._px-6._text-12._border-t._border-gray-175(v-if="cart.subtotalPrice")
              div._uppercase Subtotal
              div {{ price(cartSubtotal) }}
            //- checkout btn
            ._text-white
              a._h-20._bg-black._flex._items-center._justify-center._text-btn._uppercase(:href="checkoutURL") Checkout

        //- empty cart
        template(v-else)
          p._h-14._flex._items-center._px-6._bg-gray-100 You have no items in your shopping bag.

    //- close button as overlay bg
    //- button._absolute._overlay._pointer-events-auto._hidden._top-14(:class="{'lg__block': visible}", aria-label="Close Bag", @click="$emit('close')", @mouseenter="$emit('close')")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartLineItem from '@/components/CartLineItem'
export default {
  name: 'Cart',
  components: { CartLineItem },
  props: ['visible'],
  computed: {
    ...mapState(['cart', 'cartSubs', 'about']),
    ...mapGetters(['price', 'checkoutURL', 'cartCount']),
    cartSubtotal () {
      // console.log('cart', this.cart)
      // let total = this.cart ? Number(this.cart.subtotalPrice) : 0
      let total = this.cart ? Number(this.cart.subtotalPrice.amount) : 0

      // add subscription items
      total += this.cartSubs.reduce((acc, cur) => acc + cur.sellingPlan.price * 0.01 * cur.quantity, 0)

      return total
    }
  },
  watch: {
    visible (vis) {
      // scroll to top when opening
      if (vis) {
        this.$nextTick(() => {
          return this.$refs.scrollbody?.scroll(0, 0)
        })
      }
    }
  }
}
</script>

<style>
/*@import '../style/variables';*/

.increment-icon{
  display:inline-block;
  &:before{
    content:'';
    width:1em;
    height:1em;
    display:block;
    background-image:url('../assets/icons/plus.svg');
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
  }
  &.increment-icon--minus:before{
    background-image:url('../assets/icons/minus.svg');
  }
}

.cart {
  & progress{
    &::-webkit-progress-bar {
      background:transparent;
    }
    &::-webkit-progress-value{
      background:currentColor;
    }
    &::-moz-progress-bar {
      background:currentColor;
    }
  }
}
/*
#app .cart{
  -webkit-overflow-scrolling: touch;
  // // position: absolute;
  // // top:0; right:0;
  // // width:25%;
  // // height:100vh;
  // // overflow:hidden;
  // outline:1px solid;
  // // background:$white;
  // // z-index:10;
  // // padding-top:$nav-h;
  // // text-align:left;
  // line-height: 1.2;
  // .close-overlay{
  //   top:$nav-h;
  //   z-index:10;
  // }
  // > section{
  //   position: relative;
  //   // height:calc(100vh - #{$nav-h});
  //   // overflow-y:scroll;
  //   // -webkit-overflow-scrolling: touch;
  //   // padding-bottom:12em;
  //   &::-webkit-scrollbar{
  //     display:none;
  //   }
  //   p {
  //     padding:$pad;
  //   }
  //   > ul {
  //     list-style-type: none;
  //     > li {
  //       // padding:$pad;
  //       border-bottom-style: solid;
  //       > figure {
  //         width:7.5em;
  //         max-width: 360px;
  //         border-style:solid;
  //         margin-bottom: 1em;
  //         padding:.5em 0;
  //         > .background--contain{
  //           width:100%;
  //           padding-bottom: 110%;
  //         }
  //       }
  //       > header{
  //         margin-bottom: 1em;
  //       }
  //     }
  //   }
  // }
  // > footer{
  //   // position: absolute;
  //   // left:0; bottom:0; // top:calc(100vh - 12em);
  //   // width:100%;
  //   // background:$white;
  //   // border-top-style: solid;
  //   // > *{
  //   //   flex:1 0 6em;
  //   //   height:6em;
  //   //   padding:0 $pad;
  //   //   margin:0;
  //   // }
  // }

  // .cart__item__quantity-grp{
  //   margin:-.5em;
  //   button.level-item{
  //     padding:.5em;
  //     margin:0;
  //   }
  //   span.level-item{
  //     margin-left: .5em;
  //   }
  // }

  .increment-icon{
    display:inline-block;
    &:before{
      content:'';
      width:1em;
      height:1em;
      display:block;
      background-image:url('../assets/icons/plus.svg');
      background-size:contain;
      background-position:center center;
      background-repeat:no-repeat;
    }
    &.increment-icon--minus:before{
      background-image:url('../assets/icons/minus.svg');
    }
  }

  // @include mobile{
  //   width:100%;
  //   padding-top: $nav-h--mobile;
  //   .close-overlay{
  //     top:$nav-h--mobile;
  //   }
  //   > footer{
  //     display:flex;
  //     flex-direction:column;
  //     flex-wrap:wrap;
  //     .checkout{
  //       order:-1;
  //     }
  //   }
  // }
}*/
</style>
