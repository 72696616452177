<template lang="pug">
  header.slice-category-heading._w-full._flex._bg-gray-50.tw-borders._min-h-44.md__min-h-80(role="banner")
    h2._w-1x2._text-14._px-6._py-5.md__text-21
      router-link(:to="{name: 'shop-category', params: {category: category }}") {{ $prismic.asText(slice.primary.heading) }}
    ul._w-1x2._list-none._text-11._px-6._py-5._border-l._border-gray-175.md__text-12
      li.lg__w-2x3(v-for="item in slice.items")
        rich-text.textbody.children-mt-lh(:field="item.detail")
</template>

<script>
import RichText from '@/components/RichText'
export default {
  name: 'SliceCategoryHeading',
  components: { RichText },
  props: {
    slice: { type: Object, default: undefined },
    category: { type: String, default: 'not-found' }
  }
}
</script>

<style>
</style>
