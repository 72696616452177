<template lang="pug">
  .icon._flex(v-if="name")
    svg-phone(v-if="name === 'phone'")
    svg-clock._mt-px(v-else-if="name === 'clock'")
    svg-arrow-right-small(v-else-if="name === 'arrow-right'")
    img(v-else-if="name === 'carousel-arrow-right'", src="~@/assets/icons/2021/Arrow-White.svg")
    img(v-else-if="name === 'arrow-down-right-black'", src="~@/assets/icons/2021/Arrow-Black.svg")
    img(v-else-if="name === 'arrow-down-right-white'", src="~@/assets/icons/2021/Arrow-White.svg")
    img(v-else-if="name === 'press-download'", src="~@/assets/icons/2021/Press-Download-Arrow.svg")
    img(v-else-if="name === 'mobile-menu-back-chevron'", src="~@/assets/icons/2021/Mobile-MenuPanel-BackArrow.svg")
    img(v-else-if="name === 'mobile-burger'", src="~@/assets/icons/2021/Mobile-Burger.svg")
    img(v-else-if="name === 'menu-panels-close'", src="~@/assets/icons/2021/Panels-X-Black.svg")
    img(v-else-if="name === 'ticker-x-white'", src="~@/assets/icons/2021/Ticker-X-White.svg")
    img(v-else-if="name === 'ticker-x-black'", src="~@/assets/icons/2021/Ticker-X-Black.svg")
    img(v-else-if="name === 'truck'", src="~@/assets/icons/2021.07.08/Menu-Shipping.svg")
    img(v-else-if="name === 'breadcrumb-chevron'", src="~@/assets/icons/2021/Breadcrumb-Arrow-Black.svg", alt="arrow icon")
    img(v-else-if="name === 'bag-multiply'", src="~@/assets/icons/2021/Bag-Multiply.svg", alt="✕ icon")
    img(v-else-if="name === 'tick-mark'", src="~@/assets/icons/2021/Tick-Icon.svg", alt="tick-mark icon")
    img(v-else-if="name === 'account'", src="~@/assets/icons/2021.07.08/Menu-Accounts.svg", alt="Person Icon")
    img(v-else-if="name === 'account-filled'", src="~@/assets/icons/2021/Mobile-Menu-Account-Icon-LoggedIn.svg", alt="Person Icon", :class="clss")
</template>

<script>
import SvgClock from './SvgClock'
import SvgArrowRightSmall from './SvgArrowRightSmall'
import SvgPhone from './SvgPhone'
export default {
  name: 'Icon',
  props: {
    name: { type: String, default: undefined },
    clss: String,
    fill: String,
    border: String
  },
  components: {
    SvgClock,
    SvgArrowRightSmall,
    SvgPhone
  }
}
</script>

<style scoped>
.icon img{
  max-width: 100%;
}
</style>
