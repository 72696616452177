// Vue Router Main

import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
// async components
const Page = () => import('@/components/Page')
const Product = () => import('@/components/Product')
const Shop = () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
const Category = () => import(/* webpackChunkName: "shop" */ '../views/Category.vue')
const AppointmentsPage = () => import(/* webpackChunkName: "appointments" */ '../views/AppointmentsPage.vue')
// const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')
// const Stockists = () => import(/* webpackChunkName: "about" */ '../views/Stockists.vue')
// const Contact = () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      alias: '/cart'
      // meta: { shopNav: true }
    },
    {
      path: '/preview',
      name: 'preview',
      component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue')
    },
    {
      path: '/shop',
      name: 'shop',
      component: Shop,
      meta: { metaTitle: 'Shop', shopNav: true }
    },
    {
      path: '/shop/collections/:category',
      name: 'shop-category',
      component: Category
    },
    {
      path: '/shop/:slug',
      name: 'shop__product',
      component: Product,
      props: true,
      meta: { isOverlay: true, shopNav: true }
    },
    {
      path: '/shop/:slug/item',
      redirect: '/shop/:slug'
    },
    {
      path: '/shop/:slug/item/:variantTitle',
      name: 'shop__product__item',
      component: Product,
      props: true,
      meta: { isOverlay: true, shopNav: true }
    },
    {
      path: '/appointments',
      name: 'appointments-index',
      component: AppointmentsPage,
      meta: { hideFooter: true }
    },
    {
      path: '/appointments/:appointmentsPage',
      name: 'appointments-page',
      component: AppointmentsPage,
      meta: { hideFooter: true }
    },
    // {
    //   path: '/collections',
    //   name: 'collections',
    //   meta: { metaTitle: 'Collections' }
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: About,
    //   meta: { metaTitle: 'About', nav: 'about' }
    // },
    // {
    //   path: '/stockists',
    //   name: 'stockists',
    //   component: Stockists,
    //   meta: { metaTitle: 'Stockists', nav: 'about' }
    // },
    // {
    //   path: '/contact',
    //   name: 'contact',
    //   component: Contact,
    //   meta: { metaTitle: 'Contact', nav: 'about' }
    // },
    {
      path: '/notfound',
      name: 'page-not-found',
      component: Page,
      meta: { metaTitle: 'Page Not Found', isOverlay: true, fixedFooter: true }
    },
    {
      path: '/:uid',
      name: 'page',
      component: Page,
      props: true,
      meta: { isOverlay: true, fixedFooter: true }
    },
    {
      path: '/:uid/:sectionId',
      name: 'page__section',
      component: Page,
      props: true,
      meta: { isOverlay: true, fixedFooter: true }
    },
    {
      path: '*',
      redirect: '/notfound'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // disable browser handling saved position
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
    // wait for the route leave transition to end
    return new Promise(resolve => {
      this.app.$root.$once('afterRouteLeave', () => {
        const position = savedPosition || { x: 0, y: 0 }
        resolve(position)
      })
    })
  }
})
