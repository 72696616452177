<template lang="pug">
  section.slice-press-assets._w-full
    //- (header)
    header._bg-gray-50._w-full._flex._flex-wrap._min-h-44.tw-borders._border-t._border-gray-175._-mt-px(role="banner", v-if="hasHeader")
      //- heading
      ._flex-1._text-14._px-6._py-5.md__text-15(:class="{'md__border-r _border-gray-175': hasDescrip}")
        rich-text(:field="slice.primary.heading")
      //- description
      ._w-full.md__w-1x2._list-none._text-12._px-6._py-5._bg-white(v-if="hasDescrip")
        rich-text.children-mt-lh.textbody(:field="slice.primary.description")

    //- assets
    ul._list-none._grid._grid-cols-2._text-white._text-11.md__text-12._bg-gray-150(:class="{'md__grid-cols-3': slice.slice_label === null || slice.slide_label === '3cols', 'md__grid-cols-4': slice.slice_label === '4cols'}")
      //- items...
      li._w-full._relative._z-5(v-for="item in slice.items", v-if="item.image.url")
        //- title
        header._z-5._flex._flex-col.md__flex-row._justify-between.md__items-start._absolute._overlay._px-3._py-4.md__p-6(:style="{'mix-blend-mode': blurActive ? '' : 'difference'}")
          //- heading
          rich-text._pr-6(:field="item.heading")
          //- download links
          ._flex
            //- "high" download as body click / overlay
            a._absolute._overlay(:href="rawLink(item.download)", target='_blank', download)
              span._sr-only High
            //- prismic-link._absolute._overlay(:field="rawLink(item.download)")
              span._sr-only High

            //- (low)
            a._relative._z-10._p-2._-m-2._mr-6._group(:href="rawLink(item.download_low)", v-if="item.download_low", target='_blank', download)
              span.lg_group-hover__border-b._border-current Low
            //- prismic-link._relative._z-10._p-2._-m-2._mr-6._group(:field="rawLink(item.download_low)", v-if="item.download_low")
              span.lg_group-hover__border-b._border-current Low

            //- "high" label
            ._p-2._-m-2._flex._group(:field="item.download")
              span._mr-6(v-if="item.download_low") High
              icon._mt-px.md__mt-1(name="press-download", style="height:1em")

        //- image / grid sizer
        resp-img._bg-white(:image="item.image")
</template>

<script>
import RespImg from '@/components/RespImg'
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
export default {
  name: 'SlicePressAssets',
  props: {
    slice: Object,
    blurActive: { type: Boolean, default: false }
  },
  computed: {
    hasHeader () {
      return this.slice && (this.$prismic.asText(this.slice.primary.heading).length ||
        this.$prismic.asText(this.slice.primary.description).length)
    },
    hasDescrip () {
      return this.$prismic.asText(this.slice.primary.description).length
    }
  },
  components: { RespImg, Icon, RichText },
  methods: {
    rawLink (link) {
      if (link && link.url) {
        let url = link.url
        // remove after ?
        if (url.indexOf('?') > -1) {
          url = url.substring(0, url.indexOf('?'))
        }
        // link.url = url
        // return link
        return url
      }
    }
  }
}
</script>

<style>
</style>
