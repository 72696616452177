<template lang="pug">
  <svg xmlns="http://www.w3.org/2000/svg" width="16.707" height="9.061" viewBox="0 0 16.707 9.061">
    <path id="Path_981" data-name="Path 981" d="M0,8,8,0l8,8" transform="translate(16.354 8.354) rotate(180)" fill="none" stroke="currentColor" stroke-width="1"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgChevronDown'
}
</script>

<style>
</style>
