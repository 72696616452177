<template lang="pug">
  .cart-line-item._flex(:class="{'_bg-gray-200': isSellingPlan}")
    //- image
    router-link._block._w-48(:to="productLink")
      resp-img._relative._pb-ar-3x4(:bg="true", :image="item.variant.image", fit="_object-cover _object-center", :key="item.variant.image.src")
    //- details
    ._flex-1._flex._flex-col._justify-between._min-h-60._py-6._px-5(:class="{'_opacity-30': cartUpdating}")
      ._text-10
        //- (selling plan qty)
        div(v-if="isSellingPlan")
          | Subscription: {{ item.sellingPlan.name }}
        //- item quantity x Title
        div {{ item.quantity }} <span class="_mx-3 icon-bag-multiply"></span> <router-link :to="productLink">{{item.title}}</router-link>
        //- variant title, category
        div #[router-link(:to="productLink") {{ item.variant.title }}] #[span(v-html="attr('productType', item)")]

      //- actions
      ._flex._w-full._justify-between._mt-6._items-end
        ._flex._-mb-2b._-ml-2
          //- increase btn
          button._p-2(@click="updateItem(1)", aria-label="Increase Quantity by 1", :disabled="cartUpdating")
            img(src="~@/assets/icons/2021/Cart-Plus.svg", alt="Plus")
          //- decrease btn
          button._p-2(@click="updateItem(-1)", aria-label="Decrease Quantity by 1", :disabled="cartUpdating")
            img(src="~@/assets/icons/2021/Cart-Minus.svg", alt="Minus")
        ._text-12 {{ price }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import RespImg from '@/components/RespImg'
import Icon from '@/components/Icon'
import kb from 'lodash/kebabCase'
export default {
  name: 'CartLineItem',
  props: ['item'],
  components: { RespImg, Icon },
  computed: {
    ...mapState(['cartUpdating']),
    ...mapGetters({ formatPrice: 'price' }),
    isSellingPlan () {
      return this.item.sellingPlan !== undefined
    },
    itemQuantity () {
      return this.isSellingPlan ? 1 : this.item.quantity
    },
    price () {
      const price = this.isSellingPlan ? this.item.sellingPlan.price / 100
        : this.item.variant.price.amount
      return this.formatPrice(price * this.item.quantity)
    },
    productLink () {
      const slug = this.item.customAttributes.find(item => item.key === 'slug')?.value
      if (slug) {
        return {
          name: 'shop__product',
          params: { slug },
          query: {
            variant: kb(this.item.variant.title)
          }
        }
      }
      return '/'
    }
  },
  methods: {
    attr (key, item) {
      const attr = item.customAttributes?.find(attr => attr.key === key)
      return attr?.value
    },
    updateItem (increment) {
      if (this.isSellingPlan) {
        return this.$store.dispatch('updateSubscriptionInCart', { id: this.item.id, increment })
      }
      // else
      return this.$store.dispatch('updateCartItem', { id: this.item.id, increment })
    }
  }
}
</script>

<style>
</style>
