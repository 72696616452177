<template lang="pug">
  .footer-links.tw-borders._text-12
    //- (icon links)
    ul.lg__hidden._list-none._mx-6(v-if="contactLinks.length")
      //- account link
      li
        a._h-18._flex._w-full._items-center._border-t._border-b._-mb-px._border-gray-300(:href="$store.getters.accountURL($route.path)")
          icon._mr-4b._bottom-0b._relative._w-4(name="account", clss='_h-4')
          | Account
      //- contact...
      li._links-with-icons(v-for="item in contactLinks")
        prismic-link._h-18._flex._w-full._items-center._border-t._border-b._-mb-px._border-gray-300(:field="item.link") {{ item.link_text }}

    //- dropdowns
    .lg__hidden._border-b._border-gray-300._mx-6(v-if="dropdowns.length", :class="{'_mt-18 lg__mt-0': context === 'footer'}")
      //- dropdowns...
      dropdown(v-for="slice in dropdowns", v-if="slice.slice_type === 'mobile_menu_footer_group'")
        h6._h-18._flex._items-center(slot="summary") {{ slice.primary.heading }}
        ul._list-none
          li(v-for="item in slice.items")
            prismic-link._h-18._flex._items-center._border-t._border-gray-300(:field="item.link") {{ item.link_text }}

    //- main links
    .lg__border-t._border-gray-300._flex._justify-between._mx-6.lg__mx-0(:class="{'lg__hidden': context === 'mobile-header-menu', '_border-t _mt-18 lg__mt-0': context === 'footer'}")
      //- copyright
      ._h-18.lg__h-14._flex._items-center.lg__px-6 © Selahatin {{ new Date().getFullYear() }}

      //- (footer links)
      ._hidden.lg__block(v-for="(item, i) in mainFooterLinks")
        prismic-link._px-6.lg__px-7._flex._h-14._items-center(:field="item.link") {{ item.link_text }}

      //- (instagram)
      prismic-link.lg__hidden._h-18.lg__h-14._flex._items-center(v-if="instagramLink", :field="instagramLink") Instagram
</template>

<script>
import { mapState } from 'vuex'
import Dropdown from '@/components/Dropdown'
import Icon from '@/components/Icon'
export default {
  name: 'FooterLinks',
  props: {
    context: { type: String, default: 'footer' }
  },
  components: { Dropdown, Icon },
  computed: {
    ...mapState({
      mainFooterLinks: state => state.about?.data.footer_links || [],
      instagramLink: state => state.about?.data.instagram,
      contactLinks: state => state.about?.data.contact_links || [],
      dropdowns: state => state.about?.data.mobile_footer_groups || []
    })
  }
}
</script>

<style>
</style>
