<template lang="pug">
  observer.ticker-text(:threshold="0.5", v-if="items.length", @visible="animate = true", @hidden="animate = false")
    ._w-full._overflow-x-hidden
      ._whitespace-no-wrap(style="width: 100000px")
        .ticker-tape__body._inline-block(:class="{'ticker-tape__body--animate': animate && enabled}", :style="{animationDuration: 150 * items.length + 's'}")
          template(v-for="n in 12")
            template(v-for="(item, i) in items")
              //- TODO links don't use router-link...
              ._inline-block(style="margin-right:25vh") {{ item }}
              //- space between items
              //- span._inline-block(v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'")
</template>

<script>
import Observer from '@/components/Observer'
export default {
  name: 'SliceTickerText',
  components: { Observer },
  props: {
    items: Array
  },
  data () {
    return {
      animate: false,
      enabled: true // process.env.NODE_ENV !== 'development'
    }
  }
}
</script>

<style>
.ticker-tape__body{
  /* animation duration in style */
  animation: ticker infinite linear;
  animation-play-state: paused;
}
.ticker-tape__body--animate{
  animation-play-state: running;
}
@keyframes ticker {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-50%);
  }
}
</style>
