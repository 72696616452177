<template lang="pug">
  ul.stockists.tw-borders._list-none._text-12._-mb-px(v-if="countries")
    //- country block
    li._border-t._-mt-px._border-gray-175(v-for="(value, key) in countries")
      //- country label
      h3.scroll-point._w-full._h-18._flex._items-center._px-6._text-18.lg__h-22.lg__px-7.lg__border-b.lg__-mt-px.lg__border-gray-175(:id="kb(key)") {{key}}

      //- cities ...
      ul._list-none.lg__flex._border-t._border-gray-175.lg__-mt-px(v-for="(city, key) in value")
        //- (city label)
        h4.lg__w-1x3._h-14._flex._items-center._px-6._border-t._border-b._border-gray-175._text-15.lg__border-r._-mt-px.lg__border-b-0.lg__h-auto.lg__items-start.lg__py-8.lg__px-7 {{key}}

        //- lg: grid wrapper
        .lg__flex-1.lg__flex.lg__flex-wrap._pb-6.lg__pb-0
          //- stores...
          li.lg__w-1x2.lg__border-r.lg__border-b._border-gray-175._flex(v-for="(stockist, index) in city")
            //- info
            ._px-6._mt-7.lg__min-h-56._links-with-icons.lg__pb-6.lg__flex.lg__flex-col.lg__justify-between._w-full
              ._w-full
                //- lines..
                div(v-for="(line, index) in stockist.slice(0, 4)") {{line}}
              //- last line (web link)
              ._w-full.lg__mt-28.lg__flex._justify-end(v-if="stockist[5]")
                a.btn-theme-text(
                  :href="!isNaN(stockist[5]) ? `tel:${stockist[5]}` : `http://${stockist[5]}`",
                  target="_blank",
                  rel="noopener",
                  v-html="stockist[5]")

</template>

<script>
import Dropdown from '@/components/Dropdown'
import kb from 'lodash/kebabCase'
export default {
  name: 'Stockists',
  data () {
    return {
      countries: null
    }
  },
  computed: {
    gsheetURL () {
      return this.$store.state.about?.data.stockists_csv.url
    }
  },
  watch: {
    gsheetURL (doc) {
      this.getStockists()
    }
  },
  created () {
    this.getStockists()
  },
  methods: {
    kb,
    async getStockists () {
      // fetch stockists csv file
      return this.gsheetURL && fetch(this.gsheetURL)
        .then(resp => resp.text())
        .then(text => this.setCountries(text))
        .catch(e => console.error('Get Stockists', e))
    },
    setCountries (csvString) {
      // format string (sometimes "" string is broken onto second line)
      csvString = csvString.replaceAll('\n"', '"')
      // break string into array of rows
      let rows = csvString.split('\n')
      // console.log(rows)
      rows = rows.map((stockist) => {
        // remove spaces in front of phone numbers, temporarily change commas in cell data, like "Berlin, 12043", remove dbl quotes
        stockist = stockist.replace(/,\s\+/g, ',+').replace(/(,\s)/g, ';;;; ').replace(/"/g, '')
        stockist = stockist.split(',')
        // restore commas in cell data
        stockist = stockist.map(line => line.replace(';;;; ', ', '))
        return stockist
      })
      // remove header rows
      rows = rows.slice(2)
      // loop through each row (stockist) and group by country
      if (rows.length > 0) {
        const list = {}
        for (var i = 0; i < rows.length; i++) {
          // country is first cell
          const country = rows[i][0]
          // city is second cell
          const city = rows[i][1]
          if (city) {
            // start new country ?
            if (typeof list[country] === 'undefined') list[country] = {}
            // start new city ?
            if (typeof list[country][city] === 'undefined') list[country][city] = []
            // save stockist (exclude country and city cells)
            const stockist = rows[i].slice(2)
            list[country][city].push(stockist)
          }
        }
        // Save
        this.countries = list
      }
    }
  },
  components: { Dropdown }
}
</script>

<style lang="scss" scoped>
// @import '../style/variables';

// $lh: 1.2em;

// .stockists{
//   padding-top: 10em;
//   padding-bottom: 10em;
//   // border-bottom-style: solid;
//   text-align: left;
//   line-height: 1.2;

//   // reset
//   ul{
//     margin:0;
//     padding:0;
//     list-style-type: none;
//   }

//   > .columns{
//     padding:0 $pad/2;
//     > .column{
//       padding:0 $pad/2;
//     }
//   }

//   .city{
//     margin-top: $lh;
//     border-top-style: solid;
//     padding-top: $lh;
//     &:last-child{
//       margin-bottom: 2em;
//     }
//   }

//   .stockist{
//     & + .stockist{
//       margin-top: $lh;
//     }
//   }
// }

// @include mobile () {
//   .stockists{
//     padding:$pad*2 0;
//   }

//   .country{
//     & + .country {
//       margin-top: 4em;
//     }
//   }

//   .stockist{
//     h2{
//       margin-bottom: $lh;
//     }
//     &:nth-child(n+2){
//       h2{
//         display:none;
//       }
//     }
//   }
// }
</style>
