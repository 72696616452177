<template lang="pug">
  .cookie-banner.tw-borders.underline-links._fixed._z-50._bottom-0._left-0._w-full._bg-white._flex._items-start.md__items-center._py-8._text-10.lg__text-12(v-if="show")
    rich-text._flex-1.underline-links._px-6(:field="doc.data.cookie_disclaimer_text")
    .lg__flex-1x4._px-6
      button._h-14._px-8._border._border-gray-300._w-full._flex._justify-center._items-center.lg_hover__border-current.focus__border-current._whitespace-no-wrap(@click="acceptCookies") Close and accept
      //- icon(name="menu-panels-close")
    //- nav
      //- a(v-if="policyLink.length > 0", :href="policyLink") Cookie Policy

</template>

<script>
import { mapState } from 'vuex'
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
// const localStorage = window.localStorage
export default {
  name: 'CookieDisclaimer',
  components: { Icon, RichText },
  // props: ['doc'],
  data () {
    return {
      acceptedCookies: false
      // html: Vue.$html
    }
  },
  computed: {
    ...mapState({ doc: 'about' }),
    show () {
      if (this.doc) return this.acceptedCookies !== 'true' && this.doc.data.cookie_disclaimer_active === 'true'
      return false
    }
    // policyLink () {
    //   if (this.doc) {
    //     const link = this.doc.data.cookie_disclaimer_policy_link
    //     if (link) {
    //       if (link.link_type === 'Document' && link.type === 'legal_page') return '/' + link.uid
    //     }
    //   }
    //   return ''
    // }
  },
  methods: {
    acceptCookies () {
      this.acceptedCookies = 'true'
      localStorage.setItem('acceptedCookies', this.acceptedCookies)
    }
  },
  created () {
    this.acceptedCookies = localStorage.getItem('acceptedCookies')
  }
}
</script>

<style lang="scss" scoped>
// @import '../style/variables';
// .cookie-banner{
//   position:fixed;
//   bottom:0; left:0;
//   width:100%;
//   background:$bgColor;
//   min-height:calc(#{$nav-h} + 2px);
//   border-top-style: solid;
//   z-index:$z-cookie-banner;
//   letter-spacing:letterspace(40);
//   display:flex;
//   align-items: center;
//   > div {
//     width:100%;
//     padding:$pad/2;
//     padding-left: $pad;
//     display:flex;
//     align-items: flex-start;
//     justify-content: space-between;
//   }
//   nav {
//     display:flex;
//     margin-left: $pad/2;
//     a {
//       margin:0 $pad/2;
//       white-space:nowrap;
//       cursor: pointer;
//     }
//   }
// }

</style>
