<template lang="pug">
  .slice-scroll-point.scroll-point._w-full(:id="kb($prismic.asText(slice.primary.item_title)) + i", :data-indent="slice.slice_label")
    prismic-rich-text._sr-only(:field="slice.primary.item_title")
</template>

<script>
import kb from 'lodash/kebabCase'
export default {
  name: 'SliceScrollPoint',
  props: {
    slice: Object,
    i: Number
  },
  methods: {
    kb
  }
}
</script>

<style>
</style>
