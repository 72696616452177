<template lang="pug">
  subscribe-section.slice-newsletter-sign-up
</template>

<script>
import SubscribeSection from '@/components/SubscribeSection'
export default {
  name: 'SliceNewsletterSignUp',
  components: { SubscribeSection }
}
</script>

<style>
</style>
