<template lang="pug">
  a.app-link(:href="linkResolver(link) || link.url", @click="onClick", :target="link.target", :rel="link.target === '_blank' ? 'noopener noreferrer' : ''")
    slot
</template>

<script>
import linkResolver from '@/prismic-link-resolver'
export default {
  name: 'AppLink',
  props: {
    link: { type: Object, default: () => ({}) }
  },
  computed: {
    isScrollLink () {
      return false
      // const types = ['shop', 'collection', 'collections', 'about', 'contact', 'stockists']
      // return types.includes(this.link?.type)
    }
  },
  methods: {
    linkResolver,
    onClick (e) {
      if (this.link.link_type === 'Document') {
        e.preventDefault()
        if (this.isScrollLink) {
          this.$root.$emit('jumpTo', this.link.type.replace(/collection$/, 'collections'))
        } else {
          this.$router.push(linkResolver(this.link))
        }
      }
    }
  }
}
</script>

<style>
</style>
