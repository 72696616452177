<template lang="pug">
  form.tw-borders(@submit.prevent="submit", validate)
    //- errors / status
    //- ._p-6._uppercase {{ status }}
    //- ._overflow-hidden(:class="{'_h-0': !status}")
      div._p-6._uppercase {{ status }}
    //- form
    .sm__flex._w-full._items-end._text-12
      //- email input
      div.sm__w-1x2._bg-white
        label._sr-only Email
        input._border._border-gray-300._h-18.lg__h-20._px-6._w-full._text-black.focus__border-black(type="email", placeholder="Email", v-model="email", required, @focus="reset")

      //- submit btn / status
      button._w-full.sm__w-1x2._mt-6._h-18.lg__h-20._flex._items-center._justify-center._bg-black._text-white.hover__bg-gray-800.focus__bg-gray-800(type="submit", :class="btn", :disabled="status !== null") {{ status || 'Sign-up' }}
</template>

<script>
import Vue from 'vue'
export default {
  name: 'SubscribeInput',
  props: {
    btn: { type: String, default: '_w-full lg__w-56' }
  },
  data () {
    return {
      email: '',
      status: null
    }
  },
  methods: {
    submit () {
      this.status = 'Sending...'
      Vue.$submitForm('subscribe', { email: this.email })
        .then(resp => {
          this.status = 'Success!'
        })
        .catch(e => {
          this.status = 'Error'
          alert(e)
        })
    },
    reset () {
      this.status = null
    }
  }
}
</script>

<style>
</style>
