<template lang="pug">
  observer.press-quotes._min-h-80.lg__min-h-130._flex._items-center._justify-center._p-6.lg__px-12._text-14.lg__text-12.tw-borders(v-if="quotes.length", @visible="play", @hidden="pause")

    //- xmp(style='position: fixed; top: 30px; right: 0px; background: black; color: white; z-index: 9999; font-size: 10px; line-height: 1em;') {{ quotes }}

    //- heading-bullet In the Press
    h5._sr-only Press
    //- quotes...
    //- prismic-rich-text._uppercase(v-for="(quote, i) in quotes", :field="quote", :key="'i'+i", v-show="active === i")
    blockquote._uppercase
      animate-text-type._text-center(ref="textAnim", :text="$prismic.asText(quotes[active])", @end="next")
      //- span._uppercase {{ text }}
      //- span._border-r._ml-1(style="height:1.75em; animation-duration:1s", :class="{'animation-flashing': waiting}")
</template>

<script>
import HeadingBullet from '@/components/Heading--Bullet'
import Observer from '@/components/Observer'
import AnimateTextType from '@/components/AnimateTextType'
export default {
  name: 'PressQuotes',
  components: { HeadingBullet, Observer, AnimateTextType },
  data () {
    return {
      active: 0,
      carousel: null,
      delay: 2500
      // text: '',
      // anim: null,
      // waiting: true
    }
  },
  computed: {
    quotes () {
      return this.$store.state.about?.data.press_quotes?.map(i => i.quote) || []
    },
    activeQuote () {
      const active = this.quotes[this.active]
      return active && this.$prismic.asText(active)
    }
  },
  methods: {
    next () {
      this.carousel = setTimeout(() => {
        this.active = this.active + 1 === this.quotes.length ? 0 : this.active + 1
        this.play()
      }, this.delay)
      // this.text = this.quotes[this.active]
      // this.text = ''
      // this.animate()
    },
    // animate () {
    //   if (!this.quotes.length) return

    //   const quote = this.$prismic.asText(this.quotes[this.active])
    //   const nextChar = quote[this.text.length]
    //   if (nextChar) {
    //     requestAnimationFrame(() => {
    //       this.waiting = false
    //       this.text += nextChar
    //       // animation loop...
    //       this.anim = setTimeout(() => this.animate(), 15)
    //     })
    //   } else {
    //     // pause before next quote
    //     this.waiting = true
    //     this.anim = setTimeout(() => this.next(), 4000)
    //   }
    // },
    play () {
      this.$refs.textAnim.play()
    },
    pause () {
      clearTimeout(this.carousel)
      this.$refs.textAnim.pause()
      // clearTimeout(this.anim)
    }
  }
}
</script>

<style>
</style>
