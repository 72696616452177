<template lang="pug">
  prismic-rich-text.rich-text(ref="text", v-bind="$attrs")
</template>

<script>
export default {
  name: 'RichText',
  inheritAttrs: false,
  methods: {
    onLinksClick (e) {
      // relative / router link?
      if (e.target.host === window.location.host) {
        e.preventDefault()

        // no query or different path?
        if (!e.target.search || e.target.pathname !== this.$route.path) {
          // push full path...
          const fullPath = e.target.href.replace(e.target.origin, '')
          this.$router.push(fullPath)

        // search query?
        } else {
          // properly handle params (so ?variant isn't overwritten on product view...)
          const query = { ...this.$route.query }
          const url = new URL(e.target.href)
          url.searchParams.forEach((value, key) => {
            query[key] = value
          })
          this.$router.push({ query })
        }
      }
    }
  },
  mounted () {
    this.$el.querySelectorAll('a').forEach(a => a.addEventListener('click', this.onLinksClick))
  }
}
</script>

<style>
</style>
