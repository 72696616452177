<template lang="pug">
  .ticker-banner._h-14._bg-black._text-white._relative._z-10._flex._items-center._pointer-events-auto(v-if="ticker", @click="ticker.link && $router.push(ticker.link)", :class="{'_cursor-pointer': ticker.link}")
    ticker-text._w-full(:items="ticker.items")
    button._absolute._top-0._right-0._h-full._flex._items-center._pr-6._pl-12(@click.stop="closeTicker", style="background:linear-gradient(to right, transparent, black 80%)")
      icon(name="ticker-x-white")
</template>

<script>
import { mapState } from 'vuex'
import linkResolver from '@/prismic-link-resolver'
import TickerText from '@/components/TickerText'
import Icon from '@/components/Icon'
export default {
  name: 'TickerBanner',
  components: { TickerText, Icon },
  computed: {
    ...mapState(['about', 'tickerVisible']),
    ticker () {
      let ticker = this.about?.data.ticker_text
      if (ticker?.length) {
        ticker = {
          items: [ticker]
        }
        // link?
        const link = this.about?.data.ticker_link
        if (link?.link_type !== 'Any') {
          ticker.link = linkResolver(link)
        }
      }
      return ticker
    }
  },
  methods: {
    closeTicker () {
      this.$store.commit('hideTicker')
      this.$nextTick(() => this.$root.$emit('tickerDestroyed'))
    }
  },
  mounted () {
    if (this.ticker) this.$store.commit('showTicker')
  }
}
</script>

<style>
</style>
