<!-- edits:
 1. arrows to filled
 2. arrows revereted to lines
 -->
<template lang="pug">
  nav.breadcrumb-nav._h-14._bg-gray-250._flex._items-center._px-6._list-none._text-gray-400._text-12.tw-borders._transform._duration-100._transition(role="navigation", :class="{'_-translate-y-full _pointer-events-none': breadcrumbTucked, '_pointer-events-auto': !breadcrumbTucked}", v-if="breadcrumb.length")
    ._flex._items-center
      router-link.hover__text-black.focus__text-black._py-3(to="/") Home
    //- items
    ._flex._items-center(v-for="(item, i) in breadcrumb", :class="{'_text-black': i + 1 === breadcrumb.length}")
      router-link.hover__text-black.focus__text-black._py-3._flex._items-center._group(:to="item[1]")
        //- arrow
        icon._mx-3._opacity-30.lg_group-hover__opacity-100ff(name="breadcrumb-chevron")
        //- text
        | {{ item[0] }}

</template>

<script>
import { mapState } from 'vuex'
import Icon from '@/components/Icon'
import throttle from 'lodash/throttle'
export default {
  name: 'BreadcrumbNav',
  props: ['items'],
  components: { Icon },
  data () {
    return {
      // tucked: false,
      lastY: window.pageYOffset
    }
  },
  computed: {
    ...mapState(['breadcrumb', 'breadcrumbTucked']),
    tuckEnabled () {
      return this.$route.name !== 'page' && !this.$route.name.includes('appointments')
    }
  },
  methods: {
    onScroll: throttle(function () {
      const y = window.pageYOffset
      if (y < this.lastY) {
        if (this.breadcrumbTucked) this.$store.commit('untuckBreadcrumb')
      } else if (y > 36) { //
        if (!this.breadcrumbTucked && this.tuckEnabled) this.$store.commit('tuckBreadcrumb')
      }
      this.lastY = y
    }, 100),
    bindScroll () {
      window.addEventListener('scroll', this.onScroll)
    },
    unbindScroll () {
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  mounted () {
    this.bindScroll()
    this.$root.$on('beforeScrollLink', this.unbindScroll)
    this.$root.$on('afterScrollLink', this.bindScroll)
  }
}
</script>

<style>
/*.breadcrumb-nav__arrow{
  border-top:3px solid currentColor;
  border-right:3px solid currentColor;
  border-bottom:3px solid transparent;
  border-left:3px solid transparent;
}*/
</style>
