<template lang="pug">
  category-body.slice-category(v-if="uid", :uid="uid")
</template>

<script>
import CategoryBody from '@/components/CategoryBody'
export default {
  name: 'SliceCategory',
  props: {
    slice: { type: Object, default: undefined }
  },
  computed: {
    uid () {
      return this.slice?.primary.category?.uid
    }
  },
  components: { CategoryBody }
}
</script>

<style>
</style>
