<template lang="pug">
  .landing-off(@click="$emit('jumpToOFF', 'shop')")
    slice-body(v-if="doc", :slices="doc.data.body")
    //- slideshow(:slides="slides", :fullBleed="true", :autoplay="true", transition="landingslide", speed="5000")
    //- div.is-overlay.level.is-mobile
      .level-item
        img.landing__logo(src="../assets/logo--wht.svg", alt="Selahatin Logo")
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import SliceBody from '@/components/SliceBody'
import _throttle from 'lodash/throttle'
export default {
  name: 'Landing',
  // props: ['doc'],
  components: {
    SliceBody
  },
  data () {
    return {
      winW: window.innerWidth,
      winH: window.innerHeight
    }
  },
  computed: {
    ...mapState({ doc: 'landing' }),
    isPortrait () {
      return this.winH > this.winW
    },
    slides () {
      if (!this.doc) return []
      return this.doc.data.slideshow.filter(itm => itm.image && itm.image.url).map(itm => {
        // portrait / landscape image src
        const portraitImg = itm.image.portrait && itm.image.portrait.url
        let src = this.isPortrait && portraitImg ? portraitImg : itm.image.url
        src = Vue.$thumbSrc(src, this.winH)
        // slide object
        return { images: [src], bgpos: itm.bg_pos }
      })
    }
  },
  methods: {
    onResize: _throttle(function () {
      this.winW = window.innerWidth
      this.winH = window.innerHeight
    }, 300)
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import '../style/variables';

.landing{
  position: relative;
  // min-height:calc(100vh - #{$nav-h});
  border-bottom-style:solid;
  .slideshow{
    figure{
      img{
        max-height:calc((100vh - #{$nav-h}) * .8);
        width:auto;
      }
    }
  }
}
.landing__logo{
  cursor: pointer;
  width:20em;
}

@include mobile () {
  .landing{
    // min-height:100vh;
  }
  .landing__logo{
    max-width:20em;
    @media (orientation:portrait) {
      width:(154/320)*100vw;
    }
    @media (orientation:landscape) {
      width:40vh;
    }
  }
}

@media (min-aspect-ratio: 1350/500) {
  .landing__logo{
    width:30vh;
  }
}
</style>
