<template lang="pug">
  router-link.product-thumb._block._w-full._relative._group(:to="productRoute")
    ._pb-ar-8x9._relative
      resp-img(:bg="true", :image="productImage")
      //- (hover media)
      template(v-if="hoverMedia")
        //- (image)
        resp-img.lg_group-hover__opacity-100._opacity-0._transition._duration-200(v-if="hoverMedia.kind === 'image'", :bg="true", :image="hoverMedia")
        //- (video)
        video._absolute._overlay._object-cover._object-center.lg_group-hover__opacity-100._opacity-0._transition._duration-200(v-else-if="hoverMedia.kind === 'document'", :data-src="hoverMedia.url", autoplay, loop, muted, playsinline, v-observe="0.1", @visible="e => e.target.setAttribute('src', e.target.getAttribute('data-src'))")

    //- text bottom
    .md__absolute._bottom-0._left-0._w-full._p-6._text-black._text-12.md__text-white.md__px-7._min-h-28.md__min-h-0._bg-white.md__bg-transparent(:class='{ "force-black": textColor === "black" }')
      //- (title + price)
      ._flex._w-full._items-start.md__items-end
        //- title
        ._flex-1
          .line-clamp._text-gray-400.md__text-current
            //- title
            h5._inline._text-black.md__text-current {{ title }}
            br
            //- (flavor)
            ._inline.md__hidden {{ $prismic.asText(doc.data.flavor) }}
          //- (label - leaves on hover)
          ._hidden.md__block(:class="{'md_group-hover__hidden': hoverText.length}") {{ $prismic.asText(doc.data.label) }}
          //- (hover text)
          rich-text._hidden.md_group-hover__block(v-if="hoverText.length", :field="hoverText")
        //- price
        div._pl-3._flex-0 {{ price }}

</template>

<script>
import RespImg from '@/components/RespImg'
import kb from 'lodash/kebabCase'
import RichText from '@/components/RichText'
export default {
  name: 'ProductThumb',
  props: {
    doc: Object,
    variantTitle: String,
    image: Object,
    hoverMedia: Object,
    hoverText: { type: Array, default: () => ([]) },
    textColor: { type: String, default: 'white' }
  },
  computed: {
    product () {
      const title = this.doc?.data.shopify_product_title
      return title && this.$store.state.shopifyProducts.find(product => product.title === title)
    },
    productImage () {
      return this.image?.url ? this.image : this.doc?.data.image
    },
    productRoute () {
      const rt = { name: 'shop__product', params: { slug: this.doc.uid } }
      if (this.variantTitle) {
        rt.query = { variant: kb(this.variantTitle) }
      }
      return rt
    },
    title () {
      let title = this.$prismic.asText(this.doc.data.title).trim()
      if (this.variantTitle) {
        title += `, ${this.variantTitle}`
      }
      return title
    },
    price () {
      let price
      if (this.product) {
        const variant = this.product.variants.find(v => kb(v.title) === kb(this.variantTitle)) ||
          this.product.variants[0] // default first variant
        if (variant) {
          // price = this.$store.getters.price(variant.price)
          price = this.$store.getters.price(variant.price.amount)
        }
      }
      return price
    },
    hasHoverText () {
      return this.$prismic.asText(this.hoverText).length
    }
  },
  components: { RespImg, RichText }
}
</script>

<style>
.line-clamp{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.force-black {
  color: black !important;
}
</style>
