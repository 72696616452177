<template lang="pug">
  form(name="subscribe", @submit.prevent="onSumbit", validate)
    .form__field-grp
      label.is-hidden First Name
      input(type="text", name="first_name", v-model="formData.first_name", placeholder="First Name")
      label.is-hidden Last Name
      input(type="text", name="last_name", v-model="formData.last_name", placeholder="Last Name")
    label.is-hidden Email
    input(type="email", name="email", v-model="formData.email", placeholder="Email", required)
    input(type="submit", value="Subscribe")
</template>

<script>
import Vue from 'vue'
export default {
  name: 'SubscribeForm',
  data () {
    return {
      formData: {
        first_name: '',
        last_name: '',
        email: ''
      }
    }
  },
  methods: {
    onSumbit (event) {
      Vue.$submitForm(this.$el.name, this.formData, this.$emit('submitted'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
