<template>
  <!-- <svg id="Group_1793" data-name="Group 1793" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <circle id="Ellipse_75" data-name="Ellipse 75" cx="5" cy="5" r="5" :fill="fill" :stroke="border"/>
    <path id="Path_1419" data-name="Path 1419" d="M3705-14444.912v3.108l2.406,1.366" transform="translate(-3700 14447)" fill="none" stroke="currentColor" stroke-width="1"/>
  </svg> -->
  <!-- outline -->
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <g id="Group_2250" data-name="Group 2250" transform="translate(-19 -317.957)">
      <g id="Group_2248" data-name="Group 2248">
        <g id="Ellipse_159" data-name="Ellipse 159" transform="translate(19 317.957)" fill="none" stroke="currentColor" stroke-width="1">
          <circle cx="5" cy="5" r="5" stroke="none"/>
          <circle cx="5" cy="5" r="4.5" fill="none"/>
        </g>
        <path id="Path_1818" data-name="Path 1818" d="M3705-14444.912v3.108l2.406,1.366" transform="translate(-3681.5 14764.957)" fill="none" stroke="currentColor" stroke-width="1"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    // fill: String,
    // border: String
  }
}
</script>
