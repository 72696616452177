<template lang="pug">
  .animate-text-type._whitespace-pre-line(style="min-height:calc(2 * 1.5em)")
    span {{ animText }}
    span._border-r._ml-1(style="height:1.75em; animation-duration:1s", :class="{'animation-flashing': waiting}")
</template>

<script>
export default {
  name: 'AnimateTextType',
  props: {
    text: { type: String, default: '' }
  },
  data () {
    return {
      animText: '',
      anim: null,
      waiting: true,
      speed: 15 // ms
    }
  },
  methods: {
    play () {
      this.pause() // clear previous animations
      this.animText = ''
      this.$nextTick(() => this.animate())
    },
    pause () {
      clearTimeout(this.anim)
    },
    animate (text) {
      if (!this.text.length) return

      const str = this.text
      const nextChar = str[this.animText.length]
      if (nextChar) {
        setTimeout(() => {
          this.waiting = false
          this.animText += nextChar
          // recursive animation loop...
          this.anim = setTimeout(() => this.animate(), this.speed)
        }, 10)
      // ended...
      } else if (!nextChar && this.animText.length === this.text.length) {
        this.$emit('end')
        this.waiting = true
      }
    }
  }
}
</script>

<style>
</style>
