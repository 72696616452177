<template lang="pug">
  .heading-bullet._text-base._flex._items-center
    span._h-3._w-3._bg-white._mr-4._mb-1
    ._leading-none
      slot
</template>

<script>
export default {
  name: 'Heading--Bullet'
}
</script>

<style>
</style>
