<template lang="pug">
  //- section(:class='{ "grid_2022": grid }').slice-body._w-full
    component(v-for="(slice, i) in slices", v-if="has(slice)", :is="sliceComponents['slice--' + slice.slice_type]", :slice="slice", :i="i", v-on="$listeners", v-bind="$attrs")

  section.slice-body._w-full._flex._flex-wrap
    template(v-for="(slice, i) in slices", v-if="has(slice)")
      component(:is="sliceComponents['slice--' + slice.slice_type]", :slice="slice", :i="i", v-on="$listeners", v-bind="$attrs")

  //- section.slice-body._w-full._flex._flex-wrap
    component(v-for="(slice, i) in slices", v-if="has(slice)", :is="sliceComponents['slice--' + slice.slice_type]", :slice="slice", :i="i", v-on="$listeners", v-bind="$attrs")
</template>

<script>
// import SliceTilesDiptych from '@/slices/SliceTilesDiptych'
import SliceTile from '@/slices/SliceTile'
import SliceCategory from '@/slices/SliceCategory'
import SliceProductsRowTile from '@/slices/SliceProductsRowTile'
import SliceCategoryHeading from '@/slices/SliceCategoryHeading'
import SliceNewsletterSignUp from '@/slices/SliceNewsletterSignUp'
import SliceRelatedProducts from '@/slices/SliceRelatedProducts'
import SliceTextImages from '@/slices/SliceTextImages'
import SliceContactForm from '@/slices/SliceContactForm'
import SlicePressAssets from '@/slices/SlicePressAssets'
import SliceLegalSection from '@/slices/SliceLegalSection'
import SliceStockists from '@/slices/SliceStockists'
import SliceAccordion from '@/slices/SliceAccordion'
import SliceScrollPoint from '@/slices/SliceScrollPoint'
import SliceNestedPage from '@/slices/SliceNestedPage'
export default {
  name: 'SliceBody',
  data () {
    return {
      grid: true
    }
  },
  props: {
    slices: { type: Array, default: () => ([]) },
    templates: { type: Object, default: undefined }
  },
  computed: {
    sliceComponents () {
      return this.templates || this.$options.components
    }
  },
  methods: {
    has (slice) {
      return this.sliceComponents['slice--' + slice.slice_type] !== undefined
    }
  },
  components: {
    // 'slice--tiles_diptych': SliceTilesDiptych,
    'slice--tile': SliceTile,
    'slice--category': SliceCategory,
    'slice--products_row_tile': SliceProductsRowTile,
    'slice--category_heading': SliceCategoryHeading,
    'slice--newsletter_sign-up': SliceNewsletterSignUp,
    'slice--related_products': SliceRelatedProducts,
    'slice--text___images': SliceTextImages,
    'slice--contact_form': SliceContactForm,
    'slice--press_assets': SlicePressAssets,
    'slice--section': SliceLegalSection,
    'slice--stockists': SliceStockists,
    'slice--accordion': SliceAccordion,
    'slice--scroll_point': SliceScrollPoint,
    'slice--nested_page': SliceNestedPage
  }
}
</script>

<style lang='scss'>
/* .grid_2022 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(1fr, auto);
  .md__w-1x2,
  ._w-1x2,
  .md__w-1x4 {
    width: 100%;
  }
  ._w-full.md__w-1x2 {
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
  }
  ._w-1x2.md__w-1x4 {

  }
} */
</style>
