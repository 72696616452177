<template lang="pug">
  tile.slice-tile(v-bind="$attrs")
</template>

<script>
import Tile from '@/components/Tile'
export default {
  name: 'SliceTile',
  components: { Tile }
}
</script>
