<template lang="pug">
  section(:class='layout').slice-products-row-tile._flex-1._flex._flex-wrap.md__items-start._min-w-full
    //- items...
    //- product-thumb._w-50vw.md__w-25vw(v-for="item in slice.items", :key="item.product.id", :doc="item.product", :image="item.image", :variantTitle="item.variant_title", :hoverMedia="item.rollover_media", :hoverText="item.rollover_text")
    //- product-thumb._w-50vw.md__w-25vw(v-for="(item, i) in slice.items", :key="item.product.id + '_' + i", :doc="item.product", :image="item.image", :variantTitle="item.variant_title", :hoverMedia="item.rollover_media", :hoverText="item.rollover_text", :textColor="textColor")
    product-thumb._w-50vw.md__w-25vw(v-for="(item, i) in slice.items", :key="item.product.id + '_' + i", :doc="item.product", :image="item.image", :variantTitle="item.variant_title", :hoverMedia="item.rollover_media", :hoverText="item.rollover_text", :textColor="textColor")
    //- fill empty space
    ._flex-1._flex._flex-col._bg-gray-150
      ._w-full._pb-ar-8x9.md__hidden
      .md_hidden._flex-1._bg-white
</template>

<script>
import ProductThumb from '@/components/ProductThumb'
export default {
  name: 'SliceProductsRowTile',
  props: {
    slice: Object
  },
  computed: {
    layout () {
      if (this.slice.primary.layout === '1x4') return 'md__min-w-100vw'
      else return 'md__min-w-50vw'
    },
    textColor () {
      return this.slice.primary.text_color ? this.slice.primary.text_color : 'white'
    }
  },
  components: { ProductThumb }
}
</script>

<style>
</style>
