<template lang="pug">
form.contact-form._text-10.tw-borders(name="contact", @submit.prevent="onSumbit", validate, :class="{'_opacity-50 _pointer-events-none': sending}")

  ._mb-5._bg-white
    label._sr-only Name
    input._w-full._block._h-14._flex._items-center._justify-left._border._border-gray-300.focus__border-current._px-4(type="text", name="first_name", placeholder="Name", v-model="formData.first_name")

  //- label._sr-only Last Name
  //- input._w-full._block._h-14._flex._items-center._justify-left(type="text", name="last_name", placeholder="Last Name", v-model="formData.last_name")

  ._mb-5._bg-white
    label._sr-only Email
    input._w-full._block._h-14._flex._items-center._justify-left._border._border-gray-300.focus__border-current._px-4(type="email", name="email", placeholder="Email", v-model="formData.email", required)

  //- label._sr-only Telephone
  //- input(type="tel", name="telephone", placeholder="Telephone", v-model="formData.telephone")

  //- .select-body._border-t._border-b.lg__border-gray-300
    label._sr-only My Question
    ._w-full._block._h-14._flex._items-center._relative
      select._w-full(name="category", @change="hasSelected = true", v-model="formData.category", required)
        option(value="", disabled, selected) My Question
        option(value="General Information") General Information
        option(value="Order Status") Order Status
        option(value="Other") Other
      ._absolute._top-0._right-0._h-full._flex._items-center._justify-center._pointer-events-none
        .expand-icon

  ._mb-5._bg-white
    label._sr-only Your Message
    textarea._w-full._block._h-36._flex._items-center._justify-left._border._border-gray-300.focus__border-current._px-4._py-5(rows="1", size="1", name="message", placeholder="Your Message", v-model="formData.message", required, style="resize:none", @focus="$event = $event.target.style.minHeight = '8rem'")

  button._bg-black._text-white._flex._items-center._justify-center._h-14._w-full.focus__bg-gray-900(type="submit", :disabled="sending",) {{ status || 'Submit' }}
</template>

<script>
import Vue from 'vue'
export default {
  name: 'ContactForm',
  data () {
    return {
      expandTextarea: false,
      hasSelected: false,
      formData: {
        first_name: '',
        last_name: '',
        telephone: '',
        email: '',
        category: '',
        message: ''
      },
      status: null,
      sending: false
    }
  },
  methods: {
    onSumbit () {
      this.status = 'Sending...'
      this.sending = true
      Vue.$submitForm(this.$el.name, this.formData)
        .then(() => {
          this.status = 'Success!'
          this.sending = false
          this.resetFields()
          setTimeout(() => { this.status = null }, 3000)
          this.$emit('submitted')
        })
        .catch(e => {
          this.status = 'Error'
          alert(e)
          setTimeout(() => { this.status = null }, 3000)
          this.sending = false
        })
    },
    resetFields () {
      this.formData.first_name = ''
      this.formData.email = ''
      this.formData.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/variables';

#app form.contact-form{
  // width:100%;

  input[type="submit"]{
    border-top-style: solid;
  }

  // textarea{
  //   resize:none;
  //   height:calc(#{$pad} + #{$pad} * 1.1 + 1.4em);
  //   border-bottom-style:none;
  //   &[data-expanded]{
  //     width:66%;
  //     padding:$pad $pad $pad 2px;
  //     border-right-style:solid;
  //     height:calc(#{$pad} * 2 + 10em);
  //     @media (max-width:960px) and (min-width:$tablet) {
  //       font-size:$fs-s;
  //     }
  //   }
  // }

  .select-body{
    position: relative;
    .expand-icon{
      // show expand icon if pointer events supported
      // (otherwise can't click through)
      visibility:hidden;
      .csspointerevents &{
        visibility:visible;
        // pointer-events:none;
        // position: absolute;
        // top:$pad;
        // height:1.5em;
        // display:flex;
        // align-items: center;
        // &:before{
        //   content:'My Question';
        //   display: inline-block;
        //   margin-right: $pad;
        //   color:transparent;
        // }
      }
    }
  }

  .expand-icon{
    &:after{
      content:'';
      display:block;
      width:1.6em;
      height:.9em;
      background-image:url('../assets/icons/expand-arrow.svg');
      background-size:contain;
      background-position:center center;
      background-repeat:no-repeat;
    }
  }
}
</style>
