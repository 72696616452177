<template lang="pug">
  //- fixed layer, non-clickable so doesn't block cart btn
  ._fixed._top-0._right-0._w-screen.md__w-1x2.lg__w-1x4._pt-18.lg__pt-14._h-full._flex._flex-1._pointer-events-none(v-if="items.length")
    //- scroll body
    ._w-full._flex-1._overflow-y-scroll._pointer-events-auto(@mouseleave="autoClose", @click="autoClose")
      //- items...
      cart-line-item._bg-gray-100(v-for="item in items", :item="item")
      //- checkout btn
      ._sticky._z-10._bottom-0._left-0._w-full._text-white
        a._h-20._bg-black._flex._items-center._justify-center._text-btn._uppercase(:href="checkoutURL") Go to Checkout
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartLineItem from '@/components/CartLineItem'
export default {
  name: 'CartItemFlash',
  components: { CartLineItem },
  data () {
    return {
      items: [],
      flashTimer: null,
      duration: 3000
    }
  },
  computed: {
    ...mapState({ cart: 'cart', cartLastItem: 'cartLastItem', cartVisible: 'cartVisible' }),
    ...mapGetters(['checkoutURL', 'cartCount', 'cartAllItems'])
  },
  methods: {
    getLastItems () {
      let items = []
      if (typeof this.cartLastItem === 'string') {
        const single = this.cartAllItems.find(item => item.id === this.cartLastItem || item.variant.id === this.cartLastItem)
        if (single) items.push(single)
      } else if (typeof this.cartLastItem === 'object') {
        items = this.cartAllItems.filter(item => this.cartLastItem?.includes(item.id))
      }
      this.items = items
    },
    flastLastCartItem () {
      if (!this.cartVisible) {
        this.getLastItems()
        this.autoClose()
      }
    },
    autoClose () {
      clearTimeout(this.flashTimer)
      this.flashTimer = setTimeout(() => {
        this.close()
      }, this.duration)
    },
    // pauseTimer () {
    //   clearTimeout(this.flashTimer)
    // },
    close () {
      this.items = []
      this.$store.commit('saveCartLastItem', null)
    }
  },
  watch: {
    cartCount (count) {
      this.flastLastCartItem()
    },
    cartVisible (vis) {
      if (vis) this.close()
    }
  }
}
</script>

<style>
</style>
