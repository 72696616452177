<template lang="pug">
header#app-header._sticky._z-40._top-0._left-0._w-full._text-12.tw-borders._pointer-events-none(@mouseleave="menuVisible = null; paymentsDetailVisible = false")

  //- LOGO LAYER
  ._absolute._z-30._top-0._left-0._w-full._h-18.lg__h-14._flex._items-center._justify-center._pointer-events-none
    router-link._block._pointer-events-auto(to="/")
      img._block._h-4b.lg__h-4._w-auto(src="../assets/logo.svg")

  //- TOP ROW
  nav#app-header__top-row._relative._z-20._flex._justify-between._items-center._bg-white._pointer-events-auto
    //- Left Group
    ._flex
      //- menu toggle (mobile)
      button._p-6._h-18._flex._items-center.lg__hidden(aria-label="Toggle Menu", @click="menuVisible = !menuVisible", :class="{'_bg-gray-100': menuVisible}")
        icon(name="mobile-burger")

      //- (menu panel)
      ._fixed.lg__absolute._z-30.lg__z-10._top-0._left-0._w-9x12._w-3x4.lg__w-1x4._h-full.lg__h-screen._flex._flex-col._pointer-events-none(v-show="menuVisible", @mouseleave="menuVisible = submenu = false", :style="{height: menuHt + 'px'}")
        //- (close button / top padding)
        ._h-18.lg__h-14._flex._items-center._px-6._flex-shrink-0._bg-gray-100.lg__bg-transparent.pointer-events-auto.lg__pointer-events-none
          button.lg__hidden(@click="menuVisible = false", aria-label="Close Menu")
            icon(name="menu-panels-close")
        //- menu
        ._flex-1._pt-3.lg__pt-0._bg-gray-100._flex._flex-col._justify-between._pointer-events-auto._overflow-y-scroll.scrollbars-hidden
          ul._list-none._p-6._text-20.lg__text-24
            //- menu primary items...
            li._mb-4(v-for="slice in mainMenu")
              //- (link)
              template(v-if="slice.primary.link.link_type !== 'Any'")
                prismic-link(:field="slice.primary.link") {{ slice.primary.item_title }}
              //- (dropdown)
              template(v-else)
                dropdown(icon="none", style="border-top:none", speed="200")
                  h6(slot="summary") {{ slice.primary.item_title }}
                  ul._list-none._text-14
                    li._pt-4(v-for="item in slice.items")
                      prismic-link(:field="item.child_link") {{ item.child_link_text }}
          //- footer links / contact / help...
          footer-links(context="mobile-header-menu")

      //- (close mobile menu overlay btn)
      button._fixed._overlay._z-20.lg__hidden(:class="{'_hidden': !menuVisible}", @click="menuVisible = false", aria-label="Close Menu")

      //- left top rowlinks...
      prismic-link._hidden.lg__flex._items-center._px-6._h-14.lg_hover__bg-gray-100(v-for="(item, i) in headerMenuLeft", :field="item.link", :key="JSON.stringify(item)", :class="{'_bg-gray-100': menuVisible}", @mouseenter.native="menuVisible = !i ? true : menuVisible") {{ item.link_text }}

    //- Right Group
    ._flex._flex-row-reverse
      //- cart
      button._h-18.lg__h-14._flex._items-center._pl-3._pr-6.lg__px-6.lg_hover__bg-gray-100(@click="toggleCart", :class="{'_bg-gray-100': cartVisible}", @mouseenter="onMenuBtnHover")
        cart-counter

      //- (cart last-item flash after add)
      cart-item-flash

      //- (cart panel)
      cart.z_30._transform._translate-z-0(:visible="cartVisible", @close="cartHide")

      //- (account)
      a._h-18.lg__h-14._flex._items-center._px-3.lg__px-6.lg_hover__bg-gray-100(:href="$store.getters.accountURL($route.path)")
        //- span._hidden.lg__inline._mr-3 Account
        icon.xl__transform.xl__scale-90.xl__origin-center._h-7(name="account")

      //- (shipping link)
      button._h-18.lg__h-14._flex._items-center._px-3.lg__px-6.lg_hover__bg-gray-100(v-if="about && about.data.shipping_payments_link.link_type !== 'Any'", @mouseenter="onShippingIconHover", :class="{'lg__bg-gray-100': paymentsDetailVisible}", @click="paymentsDetailVisible = !paymentsDetailVisible")
        span._hidden.lg__inline Shipping & Payments
        icon.lg__hidden.xl__transform.xl__scale-90.xl__origin-center._h-7(name="truck")

      //- (shipping hover dropdown)
      ._absolute._z-10._top-0._right-0._pt-18.lg__pt-14._w-full.sm__w-auto.sm__pl-20._pointer-events-none(v-if="about", v-show="paymentsDetailVisible", @mouseleave="paymentsDetailVisible = false")
        ._bg-gray-100.sm__max-w-xl._pointer-events-auto
          rich-text._px-6._py-7._text-12.underline-links(:field="about.data.shipping_and_payments_dropdown_text")
          ul._border-t._border-gray-300._px-6._py-2._flex._list-none._justify-between._items-center
            //- logos...
            li(v-for="item in about.data.payment_logos", v-if="item.svg_logo.url")
              img._block(:src="item.svg_logo.url", :alt="'Payment provider:' + item.svg_logo.name")

  //- (TICKER)
  ticker-text-banner#app-header__ticker(v-if="hasTicker")

  //- (BREADCRUMB)
  breadcrumb-nav(:items="[['Shop', '/shop'], ['Title']]")

</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import Cart from '@/components/Cart'
import CartCounter from '@/components/CartCounter'
import Icon from '@/components/Icon'
import Dropdown from '@/components/Dropdown'
import TickerText from '@/components/TickerText'
import linkResolver from '@/prismic-link-resolver'
import RichText from '@/components/RichText'
import CartItemFlash from '@/components/CartItemFlash'
import FooterLinks from '@/components/FooterLinks'
import BreadcrumbNav from '@/components/BreadcrumbNav'
import TickerTextBanner from '@/components/TickerTextBanner'
export default {
  name: 'AppHeader',
  components: { Cart, CartCounter, Icon, Dropdown, TickerText, RichText, CartItemFlash, FooterLinks, BreadcrumbNav, TickerTextBanner },
  props: [
    'isOverlay',
    'activeSection',
    'activeGrp',
    'mobileNavVisible',
    // 'cartVisible',
    // 'cartCount',
    // 'shopCatalog',
    'activeShopCategory',
    // 'collections',
    'activeCollectionIndex',
    // 'cart',
    // 'currencyCode',
    'hideStockists'
  ],
  data () {
    return {
      // cartVisible: false,
      text: Vue.$text,
      chromeVersion: 0,
      menuVisible: null,
      submenu: null,
      menuHt: window.innerHeight,
      paymentsDetailVisible: false
    }
  },
  computed: {
    ...mapState({
      canHover: 'canHover',
      about: state => state.about,
      headerMenuLeft: state => state.about?.data.header_left_menu || [],
      mainMenu: state => state.about?.data.mobile_menu || [],
      // mainMenuFooterGroups: state => state.about?.data.mobile_footer_groups || [],
      cart: state => state.cart,
      cartVisible: state => state.cartVisible,
      collections: state => state.collections,
      hasTicker: state => state.about?.data.ticker_text?.length
    }),
    headerIsFixed () {
      // Manual sticky positioning for no-csspositionsticky browsers and Chrome version 61
      // Chrome 61 has a bug when switching from fixed > sticky so don't use native CSS sticky
      const sticky = window.Modernizr ? window.Modernizr.csspositionsticky : false
      const manualSticky = !sticky || this.chromeVersion === 61
      if (manualSticky) return this.activeSection !== 'home' || this.isOverlay
      return this.isOverlay
    }

  },
  methods: {
    ...mapMutations(['cartShow', 'cartHide']),
    toggleCart () {
      return this.cartVisible ? this.cartHide() : this.cartShow()
    },
    linkResolver,
    // setChromeVersion () {
    //   const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
    //   const version = raw ? parseInt(raw[2], 10) : false
    //   if (version) {
    //     document.getElementsByTagName('html')[0].classList.add('chrome-v' + version)
    //     this.chromeVersion = version
    //   }
    // },
    onMenuBtnHover () {
      if (this.canHover) this.cartShow()
    },
    onShippingIconHover () {
      if (this.canHover) {
        this.paymentsDetailVisible = true
      }
    }

  },
  watch: {
    '$route' (to, from) {
      this.cartHide()
      this.menuVisible = false
    },
    menuVisible (visible) {
      this.menuHt = window.innerHeight
      // toggle scroll when open/closed
      if (visible) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
    paymentsDetailVisible (vis) {
      if (vis) this.cartHide()
    },
    cartVisible (vis) {
      if (vis) {
        this.paymentsDetailVisible = false
      }
    }
  },
  created () {
    // this.setChromeVersion()
  }
}
</script>

<style lang="scss">
@import '../style/variables';

// #header{
//   position:relative;
//   top:0; left: 0;
//   width:100%; height:auto;
//   z-index:$z-nav;
//   // margin-bottom: -$nav-h;
//   &.is-fixed{
//     position: fixed;
//     margin:0;
//   }
//   // CSS posiiton:sticky for supported browsers, excluding Chrome 61
//   .csspositionsticky:not(.chrome-v61) &{
//     position:sticky;
//     @include from($tablet) {top:-1px;}
//     &.is-fixed{
//       position: fixed;
//       margin:0;
//     }
//   }
// }

.menu-icon{
  &:before{
    content:'';
    display:inline-block;
    width:1.3em;
    height:1.3em;
    background-image:url('~@/assets/icons/menu.svg');
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center center;
  }
  &.menu-icon--close{
    &:before{
      background-image: url("~@/assets/icons/menu--close.svg");
    }
  }
}

.nav{
  height:$nav-h;
  width:100%;
  padding:0 $pad/2;
  // border-bottom-style:solid;
  background-color:$bgColor;
  text-align: center;
  display:flex;
  // align-items:center;
  line-height: 1;
  white-space:nowrap;
  // @include from($tablet){ padding-top:1px; }
  a{
    // display: inline-block;
    margin:0 $pad/2;
    cursor: pointer;
    @include from($tablet) { padding-top:1px; }
  }
  &.subnav{
    justify-content: center;
    position: absolute; // absolute so doesn't affect DOM flow in main body
    left:0;
    top:$nav-h;
    letter-spacing: $ls-2;
    &._justify-start{
      justify-content: flex-start;
    }
    .subnav__back-btn{
      position:absolute;
      left:$pad/2;
      top:50%;
      transform:translateY(-50%);
      &:before{
        content:'';
        width:1.8em;
        height:1.1em;
        display:block;
        background-image:url(../assets/icons/arrow-back.svg);
        background-size:contain;
        background-position:left center;
        background-repeat:no-repeat;
      }
    }
  }
  .menu-icon{
    display: block;
  }
}

#nav{
  position:relative;
  z-index:20;
  display:flex;
  justify-content: space-between;
  // align-items: stretch;
  // border-bottom-style:solid;
  padding-left:0;
  padding-right:0;
  > div{
    // display:flex;
    // align-items: stretch;
    padding:0 $pad/2;
    > * {
      // display:flex;
      // align-items: center;
      margin:0 $pad/2;
    }
    &:last-child{
      padding:0;
      .nav__toggle-cart{
        padding-top:0;
        margin:0;
        display:flex;
        align-items: stretch;
        .cart-counter{
          display:flex;
          align-items: center;
          @include from($tablet) { padding-top:1px; }
          padding-left:$pad/2;
          padding-right:$pad;
        }
      }
    }
  }
  .nav__logo{
    position: absolute;
    left:50%;
    height:100%;
    transform:translateX(-50%);
    margin:0;
    display:flex;
    align-items: center;
    a{
      padding:0;
    }
    img{
      width:7.992em;
      display: block;
    }
  }
  .menu-icon{
    padding:0 $pad/2;
    margin:0;
  }
}

#mobile-nav{
  z-index:10;
  height:100vh;
  background:$white;
  padding-top:$nav-h;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  a {
    flex:1;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & + a {
      border-top-style: solid;
    }
  }
}

#app #shop-nav{
  .shop-nav__categories{
    position: absolute;
    height:1em;
    top:50%;
    left:50%;
    // pin to left side
    // animate only on wide enough screens
    @media (min-width:480px) { transition: all .5s; }
    transform:translateX(-50%) translateY(-50%);
    &.pin-left{
      left:4.5em;
      transform:translateX(0) translateY(-50%);
      > a.shop-nav__category-link:not(.route-active){
        display:none;
      }
    }
  }
  .router-link-active{
    color:$secondary;
  }
}

@include mobile () {
  .nav{
    height:$nav-h--mobile;
    &.subnav{
      top:$nav-h--mobile;
    }
  }

  #nav{
    .nav__toggle-cart--active{
      opacity:.3;
    }
  }

  #shop-nav{
    .shop-nav__categories{
      &.pin-left{
        left:3.5em;
      }
    }
    .shop-nav__products{
      a{
        display:none;
        &.router-link-active{
          display:inline-block;
        }
      }
    }
  }
}

@media (max-width:560px) {
  #app[data-route="shop__product__item"] .shop-nav__categories{
    display:none;
  }
}
</style>

<style lang="scss" scoped>
.sm__max-w-xl {
  max-width: 46rem;
}
</style>
