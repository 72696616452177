// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import PrismicVue from 'prismic-vue'
import VueScrollTo from 'vue-scrollto'
import VueLazyload from 'vue-lazyload'
import Analytics from '@/plugins/google-analytics' // google analytics init
import linkResolver from '@/prismic-link-resolver'
import 'lazysizes'
// import Promise from 'promise-polyfill' // ie11 polyfill

// ie 11 polyfills
// require('es6-object-assign').polyfill()
// require('string.prototype.endswith')
// if (!window.Promise) window.Promise = Promise

// Config
Vue.config.env = process.env.NODE_ENV || 'development'
Vue.config.PRISMIC = { API: process.env.VUE_APP_PRISMIC_API } // `https://${process.env.VUE_APP_PRISMIC_REPO}.cdn.prismic.io/api/v2` }
Vue.config.IMG = {
  url: process.env.VUE_APP_CLOUDINARY_BASE,
  sizes: {
    portrait: [360, 480, 640, 960, 1280, 1600, 1920, 2048, 2560]
  }
}
Vue.config.bkpts = {
  mobile: 768
}

// Prismic
Vue.use(PrismicVue, {
  endpoint: Vue.config.PRISMIC.API,
  linkResolver
})

// Shopify SDK
const Client = require('shopify-buy')
const config = {
  domain: process.env.VUE_APP_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.VUE_APP_SHOPIFY_ACCESS_TOKEN
}
Vue.$shopClient = Client.buildClient(config)

// Environment helper
Vue.$is = (key) => {
  return window.innerWidth <= Vue.config.bkpts[key]
}

// Get Text of Prismic field
Vue.$text = (prismicDoc, key, filter = true) => {
  if (!prismicDoc) return ''
  if (prismicDoc.data[key][0]) {
    const text = prismicDoc.data[key][0].text
    if (text) return filter ? text.replace('& ', '&&nbsp;') : text
  }
  return ''
}

// Vue Directives
Vue.use(VueScrollTo)
Vue.use(VueLazyload)

// Price formatting
// Vue.$price = {
//   _currency: '', // set in App.vue,
//   _format: '',
//   format: function (price = '', trim = true) {
//     if (typeof price === 'string') price = trim ? price.replace('.00', '') : price
//     return this._format.replace('{{amount}}', price) // `${price} ${this.currency}`
//   }
// }

// Cloudinary thumb fetching
Vue.$thumbSrc = (src, height, format = 'jpg') => {
  let optimal = 0
  const sizes = Vue.config.IMG.sizes.portrait
  height = height * window.devicePixelRatio
  for (var i = 0; i < sizes.length; i++) {
    if (height <= sizes[i]) {
      optimal = sizes[i]
      break
    }
  }
  const sizeH = optimal === 0 ? sizes[sizes.length - 1] : optimal
  return `${Vue.config.IMG.url}/image/fetch/q_auto:best,h_${sizeH},f_${format}/${src}`
}

// Update Page Meta
Vue.$meta = {
  _siteTitle: 'Selahatin',
  _siteDescription: '',
  setTitle: function (lead = false) {
    document.title = lead ? lead + ' | ' + this._siteTitle : this._siteTitle
  },
  setDescription: function (text) {
    text = !text ? this._siteDescription : text
    document.querySelector('meta[name="description"]').setAttribute('content', text)
    document.querySelector('meta[property="og:description"]').setAttribute('content', text)
  },
  reset: function () {
    this.setTitle()
    this.setDescription()
  }
}

// Submit Netlify Forms
Vue.$submitForm = async (name, json, onSuccess = false) => {
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': name, ...json })
  })
    .then(resp => {
      if (resp.status !== 200) return Promise.reject(new Error(resp.statusText))
      return true
    })
}

//
// Global directive to observe element visibility
// <div v-observe="0.1" @visible, @hidden>
Vue.directive('observe', {
  inserted: function (el, binding, vnode) {
    const threshold = binding.value || 0.5 // v-observe="0.1"
    const onIntersection = entries => {
      const eventName = entries[0].isIntersecting ? 'visible' : 'hidden'
      // emit...
      if (vnode.componentInstance) {
        // component ?
        vnode.componentInstance.$emit(eventName) // , {detail: eventDetail}); // use {detail:} to be uniform
      } else {
        // vanilla DOM element
        vnode.elm.dispatchEvent(new CustomEvent(eventName)) // , {detail: eventDetail}));
      }
    }
    // observe!
    el.observer = new IntersectionObserver(onIntersection, { threshold })
    el.observer.observe(el)
  }
  // unbind: function (el) {
  //   el.observer.unobserve(el)
  // }
})

Vue.directive('visibility', {
  inserted: function (el, binding, vnode) {
    // const threshold = binding.value || 0.5 // v-observe="0.1"
    const onResize = entries => {
      const eventName = entries[0].contentRect.width === 0 ? 'hidden' : 'visible'
      // emit...
      if (vnode.componentInstance) {
        // component ?
        vnode.componentInstance.$emit(eventName) // , {detail: eventDetail}); // use {detail:} to be uniform
      } else {
        // vanilla DOM element
        vnode.elm.dispatchEvent(new CustomEvent(eventName)) // , {detail: eventDetail}));
      }
    }
    // observe!
    el.resizeObserver = new ResizeObserver(onResize)
    el.resizeObserver.observe(el)
  }
  // unbind: function (el) {
  //   el.observer.unobserve(el)
  // }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App },
  store,

  mounted () {
    if (Vue.config.env === 'production') Analytics() // load analytics after app is loaded
  }
})
