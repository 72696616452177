/* global ga */

const init = function () {
  window.addEventListener('load', function () {
    // Init custom google analytics autotrack.js
    // https://github.com/googleanalytics/autotrack
    // custom autotrack script built from npm script (package.json) and loaded in index.html
    window.ga = window.ga || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date()
    ga('create', process.env.ANALYTICS_ID, 'auto')
    // plugins
    ga('require', 'outboundLinkTracker')
    ga('require', 'urlChangeTracker', {
      shouldTrackUrlChange: function (newPath, oldPath) {
        newPath = newPath.split('/').slice(1)
        oldPath = oldPath.split('/').slice(1)
        // always save deep paths
        if (newPath.length > 1 || oldPath.length > 1) return true
        // ignore url changes whens scrolling homepage
        const isHome = ['', 'shop', 'collections', 'about', 'stockists', 'contact']
        if (isHome.indexOf(newPath[0]) === -1 || isHome.indexOf(oldPath[0]) === -1) return true
        return false
      }
    })
    // save initial page view
    ga('send', 'pageview')
  })
}

module.exports = init
