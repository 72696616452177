<template lang="pug">
  .tile._text-white._text-left(:class="[width]")
    //- sizer (must be child)
    ._relative._group._overflow-hidden(:class="[aspectRatio]")
      //- items... (index 1 is hover media)
      figure._absolute._overlay._transition._duration-200(v-for="(item, i) in slice.items.slice(0, 2)", :class="{'_opacity-0 group-hover__opacity-100': i > 0}")
        resp-img(:bg="true", :image="image(item)")
        //- (video)
        video._absolute._overlay._object-cover._object-center(v-if="item.video_image.url", :src="item.video_image.url", loop, autoplay, muted, playsinline)

      //- type layer
      ._absolute._overlay._flex.pad
        //- padded inner, fills space via .flex
        ._relative._w-full._flex._items-center
          //- heading (top)
          ._absolute._top-0._left-0._w-full
            //- title
            //- heading-arrowed(:arrow="hasArrowIcon", :size="label === 'quarter' ? 'small' : undefined", :dotHighlight="slice.primary.highlight", :hover="slice.primary.subtitle_hover !== undefined", :caption="slice.primary.subtitle_hover || slice.primary.heading_caption")
            heading-arrowed(:arrow="hasArrowIcon", :size="label === 'quarter' ? 'small' : undefined", :dotHighlight="slice.primary.highlight", :hover="slice.primary.subtitle_hover !== undefined", :caption="slice.primary.subtitle_hover || slice.primary.heading_caption", :class='{ "_text-black": slice.primary.text_color === "black" }')

              h4._inline {{ $prismic.asText(slice.primary.heading) }}

          //- (hero text - centered)
          ._w-full(v-if="slice.primary.hero_text")
            rich-text._text-19.md__text-24._inline.children-inline(:field="slice.primary.hero_text")
            icon-flashing-dot

      //- (link overlay)
      app-link._absolute._overlay._block(v-if="slice.primary.link", :link="slice.primary.link", style="color:transparent") View more

</template>

<script>
import RespImg from '@/components/RespImg'
import AppLink from '@/components/AppLink'
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
import IconFlashingDot from '@/components/IconFlashingDot'
import HeadingArrowed from '@/components/HeadingArrowed'
export default {
  name: 'Tile',
  inheritAttrs: false,
  props: {
    slice: { type: Object, default: undefined }
  },
  computed: {
    label () {
      return this.slice.slice_label
    },
    width () {
      return {
        '_w-full': !this.label, // full
        '_w-full md__w-1x2': this.label === 'half',
        '_w-1x2 md__w-1x4': this.label === 'quarter'
      }
    },
    aspectRatio () {
      const ratios = {
        tile: {
          '_pb-ar-8x9': this.label === 'half' || this.label === 'quarter',
          '_pb-ar-16x9': !this.label
        },
        tile__hero: {
          '_pb-ar-fill md__pb-ar-16x9': true
        }
      }
      return ratios[this.slice.slice_type]
    },
    type () {
      const label = this.label
      return label === 'quarter' ? { heading: '_text-14 md__text-18', bullet: '_w-4 _h-4 md__w-5 md__w-5' } // quarter (small)
        : { heading: '_text-19 md__text-24', bullet: '_w-5 _h-5 md__w-6 md__h-6' } // full/half (large)
    },
    hasArrowIcon () {
      return this.$prismic.asText(this.slice.primary.heading).length < 50
    }
  },
  methods: {
    image ({ image }) {
      if (!this.label) {
        return image
      }
      return image[this.label]
    }
  },
  components: { RespImg, AppLink, Icon, RichText, IconFlashingDot, HeadingArrowed }
}
</script>

<style>
</style>
