<!-- edit1: center lede -->
<!-- edit2: revert lede -->
<template lang="pug">
  .subscribe-section._bg-gray-200.tw-borders.lg__flex._text-14.lg__text-12._w-full(v-if="about")
    //- lead
    observer._w-full.lg__w-1x2.children-mt-em._p-6._min-h-48.lg__min-h-80._uppercase(@visible="onVisible")
      //- animate text (once)
      animate-text-type(ref="textAnim", :text="$prismic.asText(about.data.subscribe_intro)", @end="animated = true")

    ._w-full.lg__w-1x2._flex._flex-col._justify-end._px-6._pb-6.lg__pt-6
      subscribe-input(btn="_w-full lg__w-1x2")
    //- ._w-full.lg__w-1x2._flex._flex-colff._justify-between
      //- errors / status
      ._overflow-hidden(:class="{'_h-0': !status}")
        div._p-6._uppercase {{ status }}
      //- form
      ._p-6.lg__flex._w-full._items-end._text-12
        div.lg__w-1x2
          label._sr-only Email
          input._border._border-gray-300._h-18.lg__h-20._px-6._w-full._text-black.focus__border-black(type="email", placeholder="Email", v-model="email", required)
        button._flex-1._mt-6._h-18.lg__h-20._flex._items-center._justify-center._bg-black._text-white._w-full.hover__bg-gray-800.focus__bg-gray-800(type="submit") Sign-up
    //-
      heading-bullet._mb-lh Subscribe
      template(v-if="status === 'success'")
        //- success msg
        prismic-rich-text._text-left(:field="about.data.subscribe_submit_message", @click="status = null")
      template(v-else)
        //- intro
        prismic-rich-text.children-mt-em._text-left(:field="about.data.subscribe_intro")
        //- form
        form._mt-4._flex._flex-wrap(@submit.prevent="submit", v-show="!status")
          ._w-full.md__w-1x2.md__pr-4._text-left._mb-lh.md__mb-0
            label._sr-only Email
            input._w-full._border-b._text-white._h-24._flex._items-center(type="email", placeholder="Email", v-model="email", required)
          ._w-full.md__w-1x2.md__pl-4
            button._border._h-24._flex._items-center._justify-center._w-full.btn-theme-black(type="submit") Sign up
</template>

<script>
import { mapState } from 'vuex'
import AnimateTextType from '@/components/AnimateTextType'
import Observer from '@/components/Observer'
import SubscribeInput from '@/components/SubscribeInput'
// import HeadingBullet from '@/components/Heading--Bullet'
// import Vue from 'vue'
export default {
  name: 'SubscribeSection',
  components: { AnimateTextType, Observer, SubscribeInput },
  data () {
    return {
      // email: '',
      // status: null,
      animated: false
    }
  },
  computed: {
    ...mapState(['about'])
  },
  methods: {
    // submit () {
    //   Vue.$submitForm('subscribe', { email: this.email })
    //     .then(resp => { this.status = 'success' })
    //     .catch(e => alert(e))
    // },
    onVisible () {
      if (!this.animated) {
        this.$refs.textAnim.play()
      }
    }
  }
}
</script>

<style>
</style>
