<template lang="pug">
.heading-arrow
  ._flex._items-center(:class="type")
    //- (arrow icon)
    svg-arrow-down-right._block(v-if="arrow", :class="{'_hidden group-hover__flex': hover}")
    //- heading
    slot
    //- dot highlighter
    icon-flashing-dot(v-if="dotHighlight", :size="size")

  //- heading caption
  ._text-14.md__text-18._mt-2(:class="{'_hidden group-hover__block': hover}", v-if="caption")
    rich-text(:field="caption")
</template>

<script>
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
import IconFlashingDot from '@/components/IconFlashingDot'
import SvgArrowDownRight from '@/components/SvgArrowDownRight'
export default {
  name: 'HeadingArrowed',
  props: {
    arrow: { type: Boolean, default: true },
    hover: Boolean,
    size: String,
    dotHighlight: Boolean,
    caption: Array
  },
  computed: {
    type () {
      return this.size === 'small' ? '_text-14 md__text-18'
        : '_text-19 md__text-24'
    }
  },
  components: { RichText, Icon, IconFlashingDot, SvgArrowDownRight }
}
</script>

<style>
.heading-arrow{
  & svg {
    width:auto;
    height: 0.73em;
    margin-right: 0.4em;
    transform: translateY(calc(-0.02em));
  }
}
</style>
