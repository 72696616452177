<template lang="pug">
  stockists.slice-stockists._w-full
</template>

<script>
import Stockists from '@/views/Stockists'
export default {
  name: 'SliceStockists',
  components: { Stockists }
}
</script>

<style>
</style>
