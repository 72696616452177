<template lang="pug">
  .slice-text-images._w-full.md__flex.md__flex-row-reverse
    //- images
    .md__flex-1
      figure(v-for="item in slice.items.slice(0, 1)")
        resp-img(:image="item.image")

    //- text
    prismic-rich-text.md__w-1x3._px-6._py-9.md__py-7._text-10.md__text-12.children-mt-lh(:field="slice.primary.text")
</template>

<script>
import RespImg from '@/components/RespImg'
export default {
  name: 'SliceTextImages',
  props: {
    slice: Object
  },
  components: { RespImg }
}
</script>

<style>
</style>
