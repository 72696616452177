<template lang="pug">
  .slice-contact-form._w-full.md__flex.tw-borders
    //- text
    prismic-rich-text.md__w-1x2.lg__w-1x3._px-6._py-7.children-mt-lh._text-12._border-b.md__border-b-0.md__border-r._border-gray-175._links-with-icons(:field="slice.primary.text")
    //- form
    ._w-full.md__w-1x2.lg__w-1x3._px-6._py-7.lg__pr-0
      contact-form
</template>

<script>
import ContactForm from '@/components/ContactForm'
export default {
  name: 'SliceContactForm',
  props: {
    slice: Object
  },
  components: { ContactForm }
}
</script>

<style>
</style>
