<template lang="pug">
  span._flex._items-center
    //- ._hidden.lg__inline._mr-4._tracking-wider Bag
    .lg__mb-1
      ._relative
        cart-icon._block(:class="{'_text-black': count, '_text-white': !count}")
        ._text-white._absolute._top-1x2._left-1x2._leading-none(style="transform:translate(-45%, -20%); font-size:0.85rem") {{count}}
</template>

<script>
import { mapGetters } from 'vuex'
import CartIcon from '@/components/CartIcon'
export default {
  name: 'CartCounter',
  // props: ['count', 'cartVisible'],
  components: { CartIcon },
  // data () {
  //   return {
  //     dark: false,
  //     darkTmOut: null
  //   }
  // },
  computed: {
    // ...mapState({ cart: 'cart', updating: 'cartUpdating', lastId: 'cartLastItem' }),
    ...mapGetters({ count: 'cartCount' })
  }

  // watch: {
  //   count () {
  //     this.dark = true
  //     clearTimeout(this.darkTmOut)
  //     this.darkTmOut = setTimeout(() => { this.dark = false }, 2000)
  //   }
  // }
}
</script>

<style lang="scss">
@import '../style/variables';

.cart-counter--dark{
  background:$black;
  color:$white;
}

.cart-counter{
  .is-hidden-tablet{
    display:flex;
    align-items: flex-end;
  }
  .cart-icon{
    font-size:13px;
    margin-right: .5em;
  }
}
// .cart-icon{
  // display:flex !important;
  // align-items:flex-end;
  // &:before{
    // content:'';
    // display:inline-block;
    // width:1.2em;
    // height:1.2em;
    // margin-right:.5em;
    // background-image:url('../assets/icons/cart.svg');
    // background-size:contain;
    // background-repeat:no-repeat;
    // background-position:center center;
  // }
// }
</style>
