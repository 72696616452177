import Vue from 'vue'
import Vuex from 'vuex'
import Prismic from 'prismic-javascript'
import Shopify from 'shopify-buy'
import kebabCase from 'lodash/kebabCase'

const prismic = Prismic.getApi(process.env.VUE_APP_PRISMIC_API)

// Shopify SDK
const shopify = Shopify.buildClient({
  domain: process.env.VUE_APP_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.VUE_APP_SHOPIFY_ACCESS_TOKEN
})

// console.log('shopify', shopify.product)

// shopify.product.fetchAll().then(products => {
//   console.log('shopify products', products)
// })

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // prismic admin logged in
    isLoggedIn: document.cookie.includes('io.prismic.preview'),

    canHover: window.matchMedia('(hover:hover)').matches,
    breadcrumb: [],
    breadcrumbTucked: false,
    tickerVisible: false,
    // prismic
    landing: null,
    landing2: null,
    about: null,
    shop: null,
    products: [],
    collections: [],
    categories: [],
    pages: [],
    docs: {},
    // shopify
    cart: null,
    cartVisible: window.location.pathname === '/cart',
    cartUpdating: false,
    cartLastItem: null,
    cartSubs: sessionStorage.getItem('checkoutSubs') ? JSON.parse(sessionStorage.getItem('checkoutSubs')) : [],
    shopInfo: null,
    shopifyProducts: [],
    subscriptions: {}
  },
  getters: {
    is: () => ctx => {
      return ctx === 'lg' ? window.innerWidth >= 1024
        : false
    },
    catalog (state) {
      // build Shop catalog, merging shopify products into prismic products
      let catalog = []
      if (state.products.length > 0) {
        const slices = (state.shop && state.shop.data.body) || []
        // loop through Categories
        catalog = slices.map(slice => {
          if (slice.slice_type === 'category') {
            const cat = {}
            cat.title = slice.primary.title ? slice.primary.title[0].text : 'No Title'
            cat.id = kebabCase(cat.title)
            cat.primary = slice.primary
            cat.items = []
            // loop through prismic products
            cat.items = slice.items.map((item) => {
              if (item.product) {
                // get full Prismic Doc
                // const product = _find(state.products, ['id', item.product.id])
                const product = state.products.find(prd => prd.id === item.product.id)
                if (product) {
                  // find linked Shopify Product
                  // const shopifyItem = _find(state.shopifyProducts, ['title', product.data.shopify_product_title])
                  const shopifyItem = state.shopifyProducts.find(prd => prd.title === product.data.shopify_product_title)
                  // if (shopifyItem) this.$set(product, 'shopify_product', shopifyItem) // attach
                  if (shopifyItem) product.shopify_product = shopifyItem
                  return product // save Product to the Category
                }
              }
            })
            return cat
          }
        })
      }
      return catalog
    },

    price: () => (amnt) => {
      if (isNaN(amnt)) return 'N/A'
      return '€' + Number(amnt).toFixed(2).toString().replace('.00', '')
    },

    cartAllItems (state) {
      const lineItems = state.cart?.lineItems || []
      return [...lineItems, ...state.cartSubs]
    },

    cartCount (state, getters) {
      return getters.cartAllItems.reduce((acc, cur) => acc + cur.quantity, 0)
    },

    checkoutURL (state, getters) {
      // replace myshopify.com domain with custom domain
      let url = state.cart?.webUrl
      if (!url) {
        return ''
      }

      const shopDomain = process.env.VUE_APP_SHOPIFY_DOMAIN
      if (!shopDomain) return url
      url = new URL(url)
      url.host = shopDomain

      // subs? checkout via Cart API through myshopify site
      if (state.cartSubs.length) {
        // format items...
        const items = getters.cartAllItems.map(item => {
          const row = {
            quantity: item.quantity,
            // rewrite graphql id to variant ID number
            id: Number(atob(item.variant.id).split('/').pop())
          }
          // selling plan id?
          if (item.sellingPlan) {
            row.selling_plan = item.sellingPlan.id
          }
          return row
        })

        // append as query string
        url.pathname = '/cart'
        url.searchParams.set('checkout', encodeURIComponent(JSON.stringify(items)))
      }

      return url.href
    },
    accountURL: () => (path) => {
      return `https://${process.env.VUE_APP_SHOPIFY_DOMAIN}/account?back=${window.location.origin + path}`
    }
  },
  mutations: {
    setDoc (state, { key, doc }) {
      state[key] = doc
    },
    setShop (state, doc) {
      state.shop = doc
    },
    setProducts (state, docs) {
      state.products = docs
    },
    setCollections (state, docs) {
      state.collections = docs
    },
    setCategories (state, docs) {
      state.categories = docs
    },
    newCart (state, cart) {
      state.cart = cart
      state.cartUpdating = false
      // clear subs
      state.cartSubs = []
      sessionStorage.removeItem('checkoutSubs')
    },
    setCart (state, cart) {
      state.cart = cart
      state.cartUpdating = false
    },
    setCartSubs (state, subs) {
      state.cartSubs = subs
      sessionStorage.setItem('checkoutSubs', JSON.stringify(subs))
    },
    addPage (state, doc) {
      state.pages.push(doc)
    },
    setShopifyProducts (state, prds) {
      // console.log('setShopifyProducts', prds)
      state.shopifyProducts = prds
    },
    cartIsUpdating (state) {
      state.cartUpdating = true
    },
    saveCartLastItem (state, variantId) {
      state.cartLastItem = variantId
    },
    cartShow (state) {
      state.cartVisible = true
    },
    cartHide (state) {
      state.cartVisible = false
    },
    setBreadcrumb (state, crumbs) {
      state.breadcrumb = crumbs // [['title', $route]]
    },
    tuckBreadcrumb (state) {
      state.breadcrumbTucked = true
    },
    untuckBreadcrumb (state) {
      state.breadcrumbTucked = false
    },
    saveDoc (state, doc) {
      const docs = { ...state.docs }
      docs[doc.uid] = doc
      state.docs = docs
    },
    saveSubscriptions (state, { handle, subs }) {
      const subscriptions = { ...state.subscriptions }
      subscriptions[handle] = subs
      state.subscriptions = subscriptions
    },
    showTicker (state) {
      state.tickerVisible = true
    },
    hideTicker (state) {
      state.tickerVisible = false
    }
  },
  actions: {
    async getPriorityDocs ({ commit }) {
      return prismic.then((api) => {
        return api.query(
          Prismic.Predicates.any('document.type', ['landing', 'landing2', 'about', 'shop']),
          { fetchLinks: ['legal_page.title'] }
        )
      }).then((response) => {
        // save to App data
        response.results.forEach(doc => commit('setDoc', { key: doc.type, doc: doc }))
      }, (err) => {
        console.error('Error: Get Docs failed', err)
      })
    },

    async getDoc ({ state, commit }, { type, uid }) {
      if (state.docs[uid]) return state.docs[uid]
      try {
        const api = await prismic
        const doc = (await api.query(Prismic.Predicates.at(`my.${type}.uid`, uid))).results[0]
        commit('saveDoc', doc)
        return doc
      } catch (e) {
        console.error('@getDoc', e)
      }
    },

    async getProducts ({ state, commit }) {
      if (state.products.length) return state.products
      try {
        const api = await prismic
        const docs = (await api.query(Prismic.Predicates.at('document.type', 'product'), {
          pageSize: 100,
          fetchLinks: ['product.title', 'product.shopify_product_title', 'product.label', 'product.flavor', 'product.image']
        })).results
        commit('setProducts', docs)
        return docs
      } catch (e) {
        console.error('@getProducts', e)
      }
    },

    async getCategories ({ state, commit }) {
      if (state.categories.length) return state.categories
      try {
        const api = await prismic
        const docs = (await api.query(Prismic.Predicates.at('document.type', 'category'), {
          pageSize: 100,
          fetchLinks: ['product.title', 'product.shopify_product_title', 'product.label', 'product.flavor']
        })).results
        commit('setCategories', docs)
        return docs
      } catch (e) {
        console.error('@getCategories', e)
      }
    },

    async getPage ({ state, commit }, uid) {
      return state.pages.find(doc => doc.uid === uid) ||
        prismic.then(api => api.query(Prismic.Predicates.at('my.legal_page.uid', uid)))
          .then(resp => {
            const doc = resp.results[0]
            if (doc) {
              commit('addPage', doc)
            }
            return doc
          })
    },

    async getInfoPanel (_, uid) {
      try {
        const resp = await prismic.then(api => api.query(Prismic.Predicates.at('my.info_panel.uid', uid)))
        return resp.results[0]
      } catch (e) {
        console.error(e)
      }
    },

    // SHOPIFY
    async getShopInfo ({ commit }) {
      return shopify.shop.fetchInfo().then((shop) => commit('setDoc', { key: 'shopInfo', doc: shop }))
    },
    async getShopifyProducts ({ commit }) {
      // console.log('1', shopify)
      // console.log('2', shopify.product)
      // await console.log('3,', shopify.product.fetchAll(100))
      // await shopify.product.fetchAll(100).then((items) => console.log(items)).catch((error) => console.log(error))

      // shopify.product.fetchAll(100).then((items) => console.log('4', items))

      return shopify.product.fetchAll(100).then((items) => commit('setShopifyProducts', items))
    },

    async getCart ({ commit }, { completed }) {
      commit('cartIsUpdating')

      // helper
      const newCart = log => {
        if (log) console.log(log)
        sessionStorage.removeItem('checkoutId')
        shopify.checkout.create()
          .then(cart => {
            sessionStorage.setItem('checkoutId', cart.id) // Store the ID in sessionStorage
            commit('newCart', cart)
          })
      }

      // !! marked completed (via returning from shopify checkout order status page)
      if (completed) return newCart('Cart completed. Creating new cart...')

      // check if saved cart, otherwise start new one
      const checkoutId = sessionStorage.getItem('checkoutId')
      if (!checkoutId) return newCart('No saved cart. Creating new cart...')

      // fetch saved...
      try {
        const checkout = await shopify.checkout.fetch(checkoutId)

        // !! cart already completed
        if (!checkout && checkout.completedAt) {
          return newCart('Cart already completed order. Creating new cart...')
        }
        // load saved cart
        commit('setCart', checkout)
      } catch (e) {
        // error...
        console.error(e)
        newCart('Fetch checkout by ID failed. Creating new cart...')
      }
    },

    addToCart ({ state, commit }, { variantId, productType = '', qty = 1, slug }) {
      commit('cartIsUpdating')
      commit('saveCartLastItem', variantId)
      shopify.checkout.addLineItems(state.cart.id, [{
        variantId,
        quantity: qty,
        customAttributes: [
          { key: 'productType', value: productType },
          { key: 'slug', value: slug }
        ]
      }])
        .then((cart) => commit('setCart', cart))
        .catch(e => console.error('Add to Cart failed', e))
    },

    updateCartItem ({ state, commit }, { id, increment = 1 }) {
      const item = state.cart.lineItems.find(itm => itm.id === id) // _find(state.cart.lineItems, ['id', id])
      const qty = increment === 0 ? 0 : item.quantity + increment
      commit('cartIsUpdating')
      shopify.checkout.updateLineItems(state.cart.id, [{ id: id, quantity: qty }])
        .then((cart) => { commit('setCart', cart) })
    },

    // selling plans / subscriptions
    async getProductSubscriptions ({ state, commit }, handle) {
      try {
        if (!handle) throw new Error('Product handle missing')
        if (state.subscriptions[handle]) return state.subscriptions[handle]

        let resp = await fetch(`https://${process.env.VUE_APP_SHOPIFY_DOMAIN}/products/${handle}`)
        resp = await resp.text()
        // parse data
        resp = findPlanJSON(resp)
          .replaceAll(',]', ']') // remove lazy commas at end of array items
        // parse into array
        const subs = JSON.parse(resp)
        commit('saveSubscriptions', { handle, subs })
        return subs
      } catch (e) {
        console.error('@getProductSubscription', e)
      }
    },

    addSubscriptionsToCart ({ state, commit }, subs) {
      // sub = { id, title, variant, sellingPlan, quantity }

      const cartSubs = [...state.cartSubs]
      subs.forEach(sub => {
        const index = cartSubs.findIndex(saved => saved.id === sub.id)
        if (index > -1) {
          // increase quantity
          cartSubs[index].quantity += sub.quantity
        } else {
          // add new
          cartSubs.push(sub)
        }
      })
      commit('setCartSubs', cartSubs)
      commit('saveCartLastItem', subs.map(sub => sub.id))
    },

    updateSubscriptionInCart ({ state, commit }, { id, increment = 0, quantity }) {
      const subs = [...state.cartSubs]
      const index = subs.findIndex(sub => sub.id === id)
      if (index > -1) {
        // add/subtract
        subs[index].quantity = subs[index].quantity + increment
        // remove ?
        if (!subs[index].quantity || quantity === 0) {
          subs.splice(index, 1)
        }
        commit('setCartSubs', subs)
      }
    }
  },
  modules: {
  }
})

// helpers

function findPlanJSON (str) {
  const regex = /<script id="productSellingPlanJSON">([^<)]*)/gm
  let m

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      // console.log(`Found match, group ${groupIndex}: ${match}`);
      str = match
    })
  }
  // console.log(str)
  return str
}

export function formatSubscriptionCartItem (sellingPlan, variant, product) {
  return {
    id: sellingPlan.id + '_' + variant.id, // cart line item id
    variant,
    sellingPlan,
    quantity: 1,
    title: product.title,
    customAttributes: [
      { key: 'productType', value: product.productType },
      { key: 'slug', value: product.handle }
    ]
  }
}
