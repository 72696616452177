<template lang="pug">
  section.slice-accordion._w-full.tw-borders._text-15._max-w-7xl
    header._p-6.lg__p-8
      prismic-rich-text.textbody(:field="slice.primary.intro")
    ol._list-none._border-b._border-gray-300._-mb-px
      li(v-for="(item, i) in slice.items")
        dropdown(icon="chevron-inset")
          prismic-rich-text._h-18._h-22._flex._items-center._px-6.lg__p-8(slot="summary", :field="item.item_title")
          //- description
          prismic-rich-text._px-6._pb-6.lg__px-8.lg__pb-8._text-12.underline-links.textbody(:field="item.item_body")
</template>

<script>
import Dropdown from '@/components/Dropdown'
export default {
  name: 'Page__Slice--Accordion',
  props: ['slice', 'index'],
  components: { Dropdown }
}
</script>

<style>
.slice-accordion {
  & .dropdown--open,
  & .dropdown:hover {
    @apply _border-black
  }
}
</style>
