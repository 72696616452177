<template lang="pug">
  .slice-nested-page._w-full(v-if="uid", v-observe, @visible="onVisible", @hidden="onHidden")
    template(v-if="!body.length")
      ._min-h-screen._p-10._text-12(style="animation: flashing 1s infinite") Loading...
    template(v-else)
      slice-body._w-full(:slices="body", :name="`nested-${uid}`")
</template>

<script>
export default {
  name: 'SliceNestedPage',
  props: ['slice'],
  data () {
    return {
      loaded: false,
      body: [],
      tmout: null
    }
  },
  computed: {
    uid () {
      return this.slice.primary.page_link?.uid
    }
  },
  methods: {
    onVisible () {
      if (!this.loaded) {
        // load after timeout in case using page submenu to jump down the page
        this.tmout = setTimeout(async () => {
          const doc = await this.$store.dispatch('getPage', this.uid)
          this.loaded = true
          if (doc) {
            // filter out certain slices
            const ignore = ['scroll_point', 'nested_page']
            this.body = doc.data.body.filter(slice => !ignore.includes(slice.slice_type))
          }
        }, 200)
      }
    },
    onHidden () {
      // cancel load
      clearTimeout(this.tmout)
    }
  },
  components: {
    'slice-body': () => import('@/components/SliceBody')
  }
}
</script>

<style>
</style>
