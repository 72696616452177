<template lang="pug">
#app._font-menlo._text-left(:data-route="$route.name")
  //- body - min screen height
  ._relative._min-h-screen._flex._flex-col
    //- Navs
    app-header(
      ref="header",
      :isOverlay="isOverlay",
      :activeSection="activeSection",
      :activeGrp="activeGrp",
      :mobileNavVisible="mobileNavVisible",
      :activeShopCategory="activeShopCategory",
      :activeCollectionIndex="activeCollectionIndex",
      :hideStockists="!stockistsURL",
      @jumpTo="scrollLink",
      @changeCollection="(index) => { activeCollectionIndex = index}",
      @toggleMobileNav="toggleMobileNav",
      @toggleCart="toggleCart"
    )

    main._flex-1
      transition(name="none", @after-leave="$root.$emit('afterRouteLeave')")
        router-view(@jumpTo="scrollLink", :key="$route.path")

    //- Footer
    app-footer(@jumpTo="scrollLink", @expanded="expandFooter")

  //- modals
  subscribe-pop-up
  cookie-banner
</template>

<script>
import '@/style/_root.scss'
import '@/style/tailwind.css'
import 'flickity/css/flickity.css'
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import _kebabCase from 'lodash/kebabCase'
import _throttle from 'lodash/throttle'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
// components
import AppHeader from '@/components/AppHeader'
import Logo from '@/components/Logo'
import AppFooter from '@/components/AppFooter'
import Subscribe from '@/components/Subscribe'
import CookieBanner from '@/components/CookieBanner'
import SubscribePopUp from '@/components/SubscribePopUp'
import Icon from '@/components/Icon'
export default {
  name: 'app',
  components: { AppHeader, AppFooter, Logo, Subscribe, CookieBanner, Icon, SubscribePopUp },
  data () {
    return {
      loaded: false,
      mobileNavVisible: false,
      activeSectionIndex: 0,
      activeGrp: null,
      activeShopCategoryIndex: 0,
      activeCollectionIndex: 0,
      yPos: 0,
      lastYPos: 0,
      cancelScroll: null,
      get: _get
    }
  },
  computed: {
    ...mapState(['about', 'shopifyProducts', 'cart', 'shop', 'products', 'collections']),
    ...mapGetters({ shopCatalog: 'catalog' }),
    isOverlay () {
      return this.$route.meta.isOverlay
    },
    activeSection () {
      return this.$route.name
    },
    activeShopCategory (index) {
      const category = this.shopCatalog ? this.shopCatalog[this.activeShopCategoryIndex] : false
      return category ? category.id : ''
    },
    includeSubscribe () {
      // if (this.about) return this.about.data.show_subscribe_form
      return this.about?.data.show_subscribe_form
    },
    stockistsURL () {
      return _get(this.about, 'data.stockists_csv.url')
    }
  },
  watch: {
    loaded (loaded) {
      if (loaded) this.loadAtRoute()
    },
    '$route' (to, from) {
      // set shop category on shop item
      if (to.name === 'shop-item') this.setActiveShopCategoryIndex()
      // close menus when entering home
      if (to.name === 'home') {
        this.mobileNavVisible = false
        // this.cartVisible = false
      }
      // reset document.title
      if (!to.meta.isOverlay) Vue.$meta.setTitle()
    },
    yPos (newValue, oldValue) {
      this.lastYPos = oldValue
    },
    activeSectionIndex (value) {
      if (this.$route.matched.length === 1) {
        const route = this.$el.querySelectorAll('.route')[value]
        if (this.isOverlay) {
          this.$router.push({ path: route.getAttribute('data-path') })
        } else {
          this.$router.replace({ path: route.getAttribute('data-path') })
          this.activeGrp = route.getAttribute('data-group')
        }
      }
    },
    shopCatalog () {
      // set active shop category if entered site on shop-item
      this.setActiveShopCategoryIndex()
    },
    activeCollectionIndex () {
      this.scrollLink('collections')
    },
    about () {
      // set meta helper based on [about] doc defaults
      Vue.$meta._siteTitle = Vue.$text(this.about, 'site_meta_title', false)
      Vue.$meta._siteDescription = Vue.$text(this.about, 'site_meta_description', false)
      // TODO move to Home.vue
      // Vue.$meta.setTitle(this.$route.meta.metaTitle)
      // Vue.$meta.setDescription()
    }
  },
  methods: {
    // update (key, data) {
    //   this[key] = data
    // },
    scrollLink: _throttle(function (name, id = null) {
      // push route if leaving overlay
      if (this.isOverlay) this.$router.push({ name: name })
      // cancel previous scrollTo
      if (typeof this.cancelScroll === 'function') this.cancelScroll()
      // build new scroll
      const scroll = () => {
        this.$nextTick(() => {
          const navH = this.$refs.header.$el.offsetHeight
          const applyOffset = name !== 'shop' || (Vue.$is('mobile') && name === 'shop' && id !== null)
          const options = {
            offset: applyOffset ? navH * -2 + 1 : 0
          }
          id = id === null ? name : id
          // store to allow cancellation
          this.cancelScroll = this.$scrollTo('#' + id, 1000, options)
        })
      }
      return this.$nextTick(scroll()) // wait for DOM calculations (need two ticks :/)
    }, 300),
    toggleCart () {
      // this.cartVisible = !this.cartVisible
      this.mobileNavVisible = false
      // if (this.$route.name === 'home') this.scrollLink('shop')
    },
    toggleMobileNav (value) {
      this.mobileNavVisible = typeof value === 'boolean' ? value : !this.mobileNavVisible
      this.cartVisible = false
      if (this.$route.name === 'home') this.scrollLink('shop')
    },
    loadAtRoute () {
      const el = this.$el.querySelector('#' + _kebabCase(this.$route.name))
      if (el) window.scrollTo(0, el.offsetTop)
    },
    onScroll: _throttle(function () {
      if (!this.isOverlay) {
        this.yPos = window.pageYOffset
        this.activeSectionIndex = this.getActiveIndex('.route')
        this.activeShopCategoryIndex = this.getActiveIndex('.shop__category')
      }
    }, 50),
    getActiveIndex (selector) {
      // Get Index of current Section based on the scroll position
      const sections = this.$el.querySelectorAll(selector)
      const noOffset = () => {
        const upwards = this.lastYPos > this.yPos // scorlling upwards or downwards
        return this.$route.name === 'home' || (this.$route.name === 'shop' && upwards)
      }
      const offset = noOffset() ? 0 : this.$refs.header.$el.offsetHeight * 2
      let index = 0
      for (var i = 0; i < sections.length; i++) {
        index = this.yPos >= sections[i].offsetTop - offset ? i : index
      }
      return index
    },
    setActiveShopCategoryIndex () {
      // get the active shop category based on the current product being viewed
      if (this.$route.name === 'shop-item') {
        const slug = this.$route.params.slug // url slug of the product
        if (slug && this.shopCatalog.length > 0) {
          // loop through shop categories
          for (var i = 0; i < this.shopCatalog.length; i++) {
            const match = _findIndex(this.shopCatalog[i].items, 'uid', slug)
            // return first matched category
            if (match > -1) { this.activeShopCategoryIndex = match; break }
          }
        }
      }
    },
    expandFooter () {
      // expand footer toggle on mobile
      const toBottom = () => { window.scrollTo(0, document.body.scrollHeight * 2) }
      this.$nextTick(toBottom)
    }
  },
  created () {
    this.$store.dispatch('getPriorityDocs') // this.getDocs()
    // this.$store.dispatch('getProducts')
    this.$store.dispatch('getShopInfo')
    this.$store.dispatch('getShopifyProducts')
    this.$root.$on('jumpTo', this.scrollLink)
  },
  async mounted () {
    // timeout for shop DOM loaded
    setTimeout(() => { this.loaded = true }, 5000)

    // setup cart
    await this.$store.dispatch('getCart', { completed: this.$route.query.checkout_completed })

    // show cart if '/#cart'
    if (this.$route.hash === '#cart') {
      this.$store.commit('cartShow')
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style>
/* vue-lazyload load image transitions*/
[lazy]{
  transition: opacity .5s;
}
[lazy="loading"]{
  opacity:0;
}
</style>
