<template lang="pug">
  .app-footer(v-if="doc && !$route.meta.hideFooter")
    //- (subscribe)
    subscribe-section(v-if="doc.data.show_subscribe_form === 'true' && $route.name !== 'home'")

    //- press-quotes
    ._flex.tw-borders._bg-gray-200._border-t._border-gray-300
      prismic-rich-text._hidden.lg__block._w-1x2._border-r._border-gray-300._p-6._text-12._links-with-icons(:field="doc.data.contact_details_in_footer")
      press-quotes._w-full.lg__w-1x2

    footer.tw-borders._bg-gray-200._border-t._border-gray-300._pt-18.lg__pt-0.lg__border-t-0
      //- (contact links)
      footer-links(:copyrightGlobal="true", context="footer")

</template>

<script>
import { mapState } from 'vuex'
import PressQuotes from '@/components/PressQuotes'
import SubscribeSection from '@/components/SubscribeSection'
import FooterLinks from '@/components/FooterLinks'
export default {
  name: 'AppFooter',
  components: { FooterLinks, PressQuotes, SubscribeSection },
  computed: {
    ...mapState({ doc: 'about' }),
    year () {
      const date = new Date()
      return date.getUTCFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
