<template lang="pug">
  section.slice-legal-section._w-full._border-t.tw-borders._border-gray-175._-mt-px
    header._px-6._pt-8
      prismic-rich-text._text-15(:field="slice.primary.title")
    prismic-rich-text._p-6.lg__p-7.lg__w-2x3.md__text-columns-2._text-columns-gap-md.children-mt-lh._text-10.underline-links.textbody(:field="slice.primary.text")
</template>

<script>
export default {
  name: 'SliceLegalSection',
  props: {
    slice: Object
  }
}
</script>

<style>
.slice-legal-section{
  & h4 {
    font-size:calc(12/10 * 1em);
  }
}
</style>
