<template>
  <svg style="width:auto;height:1.8rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15.553">
    <g id="Group_2254" data-name="Group 2254" transform="translate(-1410 -9.91)">
      <path id="Path_1556" data-name="Path 1556" d="M369.7,289.279h0a3.686,3.686,0,0,1,3.687,3.687v2.765a3.686,3.686,0,0,1-3.687,3.687h0a3.687,3.687,0,0,1-3.687-3.687v-2.765A3.687,3.687,0,0,1,369.7,289.279Z" transform="translate(1047.303 -278.869)" fill="none" stroke="#000" stroke-width="1"/>
      <rect id="Rectangle_413" data-name="Rectangle 413" width="13" height="10" transform="translate(1410.5 14.963)" fill="currentColor" stroke="#000" stroke-width="1"/>
    </g>
  </svg>

  <!-- <svg style="width:auto;height:1.8rem" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <path d="M3.31305 5.05692V4.19092C3.31305 3.21306 3.7015 2.27525 4.39294 1.5838C5.08439 0.892355 6.02215 0.503906 7 0.503906V0.503906C7.97751 0.503906 8.91506 0.892085 9.60645 1.5831C10.2978 2.27411 10.6865 3.2114 10.687 4.1889V5.05692" stroke="black"/>
    <path d="M13.5 5.05292H0.5V15.0529H13.5V5.05292Z" stroke="black" fill="currentColor"/>
  </svg> -->
  <!-- <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.17099 0.5C8.72113 0.499869 9.26591 0.608133 9.7742 0.818604C10.2825 1.02907 10.7443 1.33764 11.1333 1.72665C11.5223 2.11566 11.8309 2.57749 12.0414 3.08579C12.2519 3.59408 12.3601 4.13885 12.36 4.689V7.83099C12.3602 8.38122 12.2521 8.92613 12.0417 9.43454C11.8312 9.94295 11.5227 10.4049 11.1337 10.794C10.7447 11.1831 10.2828 11.4918 9.77441 11.7023C9.26606 11.9128 8.72122 12.0211 8.17099 12.021V12.021C7.06 12.021 5.99448 11.5797 5.20889 10.7941C4.4233 10.0085 3.98196 8.94299 3.98196 7.832V4.69C3.98183 4.13981 4.09011 3.59499 4.30057 3.08664C4.51103 2.57829 4.81956 2.11639 5.20856 1.7273C5.59755 1.3382 6.05939 1.02955 6.56769 0.81897C7.07599 0.60839 7.6208 0.5 8.17099 0.5Z" stroke="black"/>
    <path d="M15.501 5.255H0.500977V17.255H15.501V5.255Z" fill="currentColor" stroke="black"/>
  </svg> -->
</template>

<script>
export default {
  name: 'CartIcon',
  props: {
    invert: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
</style>
