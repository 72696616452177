<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12.892" height="6.48" viewBox="0 0 12.892 6.48">
  <g id="Group_1893" data-name="Group 1893" transform="translate(-773.75 -842.635)">
    <path id="Path_1723" data-name="Path 1723" d="M8437.25-11161.549h11.739" transform="translate(-7663.5 12007.449)" fill="none" stroke="currentColor" stroke-width="1"/>
    <path id="Path_1724" data-name="Path 1724" d="M8449.721-11182.012l2.887,2.887-2.887,2.887" transform="translate(-7666.673 12025)" fill="none" stroke="currentColor" stroke-width="1"/>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    // fill: String
  }
}
</script>
