<template lang="pug">
  landing.home
</template>

<script>
import Landing from '@/components/Landing'
export default {
  name: 'Home',
  components: { Landing },
  mounted () {
    this.$store.commit('setBreadcrumb', [])
  }
}
</script>

<style>
</style>
