<template lang="pug">
  .subscribe-popup(v-if="enabled")
    ._fixed._z-50._overlay._p-6.lg__px-12._w-full._border-none._bg-transparent._pointer-events-none._flex._items-center._justify-center._pointer-events-none(ref="dialog", v-show="visible")
      //- card
      ._bg-gray-100._p-6._relative._pointer-events-auto._w-full._shadow-lg.lg__w-1x2
        ._flex._items-start._justify-between
          h6._text-15._mb-6 Subscribe
        //- lede
        rich-text._text-12._mb-11(:field="intro")

        ._flex._items-end
          figure._hidden.lg__block._w-1x4.xl__w-1x3._mr-6(v-if="image")
            resp-img(:image="image")
          //- input
          subscribe-input._flex-1(:btn="''")

        button._absolute._top-0._right-0._p-6.focus__outline-black(@click="close", aria-label="Close")
          icon(name="ticker-x-black")

    //- show observer
    ._w-px._h-px._-mt-px(v-if="visible !== false", v-observe="0.01", @visible="onEndofPage")
</template>

<script>
import { mapState } from 'vuex'
import SubscribeInput from '@/components/SubscribeInput'
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
import RespImg from '@/components/RespImg'
import Observer from '@/components/Observer'
export default {
  name: 'SubscribePopUp',
  components: { SubscribeInput, Icon, RichText, RespImg, Observer },
  data () {
    return {
      visible: null // false means user closed
    }
  },
  computed: {
    ...mapState({
      enabled: state => state.about?.data.show_subscribe_popup === 'true' || false,
      intro: state => state.about?.data.subscribe_intro,
      image: state => state.about?.data.subscribe_popup_image
    })
  },
  methods: {
    close () {
      this.visible = false
      sessionStorage.setItem('subscribe_popup_hidden', 1)
    },
    show () {
      if (!sessionStorage.getItem('subscribe_popup_hidden')) {
        this.visible = true
      }
    },
    onEndofPage () {
      if (this.visible === null) {
        this.show()
      }
    }
  }
}
</script>

<style>
/* don't use dialgo because odd click blocking in backdrop */
/*dialog::backdrop{
  background: transparent;
  pointer-events: none;
}*/
</style>
