<template lang="pug">
  slice-body.category-body(v-if="doc", :slices="doc.data.body", :category="doc.uid")
</template>

<script>
export default {
  name: 'CategoryBody',
  props: ['uid'],
  computed: {
    doc () {
      return this.uid && this.$store.state.categories.find(doc => doc.uid === this.uid)
    }
  },
  components: {
    'slice-body': () => import('@/components/SliceBody.vue')
  }
}
</script>

<style>
</style>
