<template lang="pug">
  .subscribe(v-if="doc")
    .columns.is-gapless
      header.column.is-half.is-mobile.level(@click="submitted = false")
        div.level-item
          h1.font-selahatin Subscribe
      section.column.is-half.is-mobile.level
        div.level-item
          div.form-body(v-if="!submitted")
            .form-body__intro.level.is-mobile
              prismic-rich-text.level-item(:field="doc.data.subscribe_intro")
            subscribe-form(@submitted="submitted = true")
          prismic-rich-text.textbody.underline-links(v-else, :field="doc.data.subscribe_submit_message")
</template>

<script>
// import Vue from 'vue'
import { mapState } from 'vuex'
import SubscribeForm from '@/components/SubscribeForm'
export default {
  name: 'Subscribe',
  // props: ['doc'],
  components: {
    SubscribeForm
  },
  data () {
    return {
      // html: Vue.$html,
      submitted: false
    }
  },
  computed: {
    ...mapState({ doc: 'about' })
  },
  methods: {
    onSubmit () {
      this.submitted = true
    }
  }
}
</script>

<style lang="scss">
@import '../style/variables';

#app .subscribe{
  border-bottom-style: solid;
  h1{
    font-size:$fs-l;
  }
  .column{
    min-height:70vh;
    & + .column{
      @include mobile () {
        border-top-style: solid;
      }
    }
    &:nth-child(-n+1){
      @include from($tablet) {
        border-right-style: solid;
      }
    }
    > div{
      margin:0;
      padding:0 $pad;
      width:100%;
    }
  }
  .form-body{
    width:100%;
    padding:$pad*2 $pad*2;
  }
  .form-body__intro{
    height:12.8em;
    max-width:38em;
    margin:0 auto;
    .level-item{
      width:100%;
    }
  }
}

// Mobile

@include mobile () {
  #app .subscribe {
    h1{
      font-size:1.4em;
    }
    .column{
      min-height:calc((100vh - #{$nav-h--mobile} * 2 ) / 2);
    }
    .form-body{
      padding:$pad*2 0;
    }
    .form-body__intro{
      height:auto;
    }
  }
}
</style>
