<template lang="pug">
  .dropdown.tw-borders._border-t._border-gray-300(:class="{'dropdown--open': expanded}")
    //- summary
    ._cursor-pointer._flex._w-full._items-center._justify-between._select-none(@click.prevent="toggle")
      slot(name="summary")
      //- (icon: +/x)
      svg-plus-x._transform._transition._duration-200._w-12._h-12(v-if="icon.includes('plus')", :class="{'_rotate-45': !expanded, '_mr-5': icon.includes('inset')}")
      //- (icon: v/^)
      svg-chevron-down._transform(v-else-if="icon.includes('chevron')", :class="{'_rotate-180': expanded, '_mr-6': icon.includes('inset')}")
    //- body
    ._overflow-hidden(:style="{transition: `max-height ${speed}ms`, maxHeight: expanded ? `${bodyHt}px` : 0}")
      div(ref="body")
        slot
</template>

<script>
// TODO - use ResizeObserver to recalc body ?
import SvgChevronDown from '@/components/SvgChevronDown'
import SvgPlusX from '@/components/SvgPlusX'

export default {
  name: 'Dropdown',
  components: { SvgChevronDown, SvgPlusX },
  props: {
    speed: { type: String, default: '250' },
    loadOpen: Boolean,
    icon: { type: String, default: 'plus' }
  },
  data () {
    return {
      expanded: false,
      bodyHt: '0'
    }
  },
  methods: {
    toggle () {
      return !this.expanded ? this.expand() : this.collapse()
    },
    expand () {
      this.bodyHt = this.$refs.body.offsetHeight
      this.$nextTick(() => {
        this.expanded = true
      })
    },
    collapse () {
      this.expanded = false
    }
  },
  mounted () {
    this.expanded = this.loadOpen
    if (this.expanded) this.expand()
  }
}
</script>

<style lang="scss">
// details.dropdown > summary {
//   list-style: none;
//   &::-webkit-details-marker { display: none; }
//   &:focus{ outline:none; }
// }
</style>
