export default function (doc) {
  if (doc.type === 'shop') return '/shop'
  if (doc.type === 'collection') return '/collections'
  if (doc.type === 'product') return '/shop/' + doc.uid
  if (doc.type === 'legal_page') return '/' + doc.uid
  if (doc.type === 'category') return '/shop/collections/' + doc.uid
  if (doc.type === 'info_panel') return '?info=' + doc.uid
  if (doc.type === 'appointment_page') {
    if (doc.uid === 'appointments') {
      return '/appointments'
    }
    return '/appointments/' + doc.uid
  }
  return '/'
  // Fallback
  // return '/doc/' + doc.uid
  // return undefined
}
