<template lang="pug">
  .slice-related-products._w-full
    h5._min-h-36._bg-gray-50._px-6._py-5.lg__min-h-44.lg__px-8.lg__py-7._text-14.lg__text-21 Related
    ul._list-non(ref="carousel")
      .carousel-cell._w-1x2.lg__w-1x4._cursor-pointer(v-for="item in slice.items", @click="onCellClick(item)")
        //- product-thumb._pointer-events-none(:doc="item.product", :image="item.image1", :variantTitle="item.variant_title")
        product-thumb._pointer-events-none(:doc="item.product", :image="item.image1", :variantTitle="item.variant_title", :textColor="slice.primary.text_color ? slice.primary.text_color : 'black'")
</template>

<script>
import ProductThumb from '@/components/ProductThumb'
import Flickity from 'flickity'
import linkResolver from '@/prismic-link-resolver'
export default {
  name: 'SliceRelatedProducts',
  props: {
    slice: Object
  },
  data () {
    return {
      flkty: null,
      click: false
    }
  },
  methods: {
    initCarousel () {
      const isLg = this.$store.getters.is('lg')
      const ttl = this.slice.items.length
      const loops = isLg ? ttl > 4 : ttl > 2
      //
      this.flkty = new Flickity(this.$refs.carousel, {
        cellAlign: isLg ? 'left' : 'center',
        draggable: loops,
        wrapAround: loops,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: isLg ? 0 : 1
        // autoPlay: 4000, // duration
        // pauseAutoPlayOnHover: false
      })
      // detect static clicks for routing
      this.flkty.on('staticClick', () => { this.click = true })
    },
    onCellClick (item) {
      if (this.click) {
        this.$router.push(linkResolver(item.product))
      }
      this.click = false
    }
  },
  mounted () {
    this.initCarousel()
  },
  components: { ProductThumb }
}
</script>

<style>
</style>
